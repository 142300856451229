import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "app/hooks";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PungoInput from "pungo-ui/PungoInput";
import PungoButton from "pungo-ui/PungoButton";
import { isEmail, isPhone } from "components/utils/validations";
import { sentContactForm } from "store/actions/contactFormActions";

import styles from "./index.module.scss";

const ContactForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [hoveredButton, setHoveredButton] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = {
      email: email,
      phone: phoneNumber,
      name: name,
      lastName: lastName,
      company: company,
      message: message,
    };

    setEmail("");
    setPhoneNumber("");
    setName("");
    setLastName("");
    setCompany("");
    setMessage("");

    dispatch(sentContactForm(data));
  };

  const verifyEmail = (e: any) => {
    setEmail(e);
    if (isEmail(e)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  const verifyPhoneNumber = (e: any) => {
    setPhoneNumber(e);
    if (isPhone(e)) {
      setIsValidPhone(true);
    } else {
      setIsValidPhone(false);
    }
  };

  return (
    <>
      <PungoSectionCard title={t("ContactSection.title")} description={`${t("ContactSection.description")}`} id="contactUs" distribution="block">
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <PungoInput
              name={t("ContactSection.email")}
              value={email}
              onChange={(e: any) => verifyEmail(e)}
              helperText={isValidEmail ? undefined : `${t("accountPage.invalidEmail")}`}
              required="required"
            />
            <PungoInput
              name={t("ContactSection.phone")}
              value={phoneNumber}
              onChange={(e: any) => verifyPhoneNumber(e)}
              helperText={isValidPhone ? undefined : `${t("accountPage.invalidPhone")}`}
              required="required"
            />
            <PungoInput name={t("ContactSection.name")} value={name} onChange={(e: any) => setName(e)} required="required" />
            <PungoInput name={t("ContactSection.lastName")} value={lastName} onChange={(e: any) => setLastName(e)} required="required" />
            <PungoInput name={t("ContactSection.company")} value={company} onChange={(e: any) => setCompany(e)} required="required" />
            <PungoInput name={t("ContactSection.message")} value={message} onChange={(e: any) => setMessage(e)} multiline={true} rows={3} />
          </div>
          <div className={styles.submitSection}>
            <div className={styles.submitButton} onMouseOver={() => setHoveredButton(true)} onMouseOut={() => setHoveredButton(false)}>
              <PungoButton
                label={`${t("accountPage.signUp")}`}
                onClick={handleSubmit}
                color="tertiary"
                disabled={!isValidEmail || !isValidPhone || !name || !lastName || !company}
                classNames={styles.submitButtonOver}
              />
              <div className={styles.submitNote}>{hoveredButton && (!isValidPhone || !isValidEmail || !name || !lastName || !company) && <p>{t("accountPage.reviewRequiredFields")}</p>}</div>
            </div>
          </div>
        </div>
      </PungoSectionCard>
    </>
  );
};

export default ContactForm;

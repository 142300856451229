import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

import {
  IEmissionSummary,
  ICostSummary,
  selectProPlanResults,
} from "store/slices/plan";

import PungoDemoAccordion from "pungo-ui/PungoDemoAccordion";
import { convertDateToUTC } from "../utils";
import SelectableTabs from "../../common/SelectableTabs";

import colors from "styles/export.module.scss";
import styles from "./index.module.scss";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const PredictionSection: React.FC = () => {
  const { t } = useTranslation();
  const proPlanResults = useSelector(selectProPlanResults);
  const emissionPrediction = proPlanResults?.emissionPrediction || [];
  const costPrediction = proPlanResults?.costPrediction || [];
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [emissionSummary, setEmissionSummary] = useState<IEmissionSummary[]>(
    proPlanResults?.emissionSummary
  );
  const [costSummary, setCostSummary] = useState<ICostSummary[]>(
    proPlanResults?.costSummary
  );

  const isEmissionTable = selectedTab === 0;

  const availableDates = {
    startDate:
      convertDateToUTC(new Date(proPlanResults?.emissionSummary[0].date)) ||
      new Date(),
    endDate:
      convertDateToUTC(
        new Date(
          proPlanResults?.emissionSummary[
            proPlanResults?.emissionSummary.length - 1
          ].date
        )
      ) || new Date(),
    key: "selection",
  };

  const predictionData = {
    labels: isEmissionTable
      ? emissionSummary
          ?.map(({ date }) => date)
          .concat(emissionPrediction.map(({ date }) => date))
      : costSummary
          ?.map(({ date }) => date)
          .concat(costPrediction.map(({ date }) => date)),

    datasets: [
      {
        label: `${t("proPlan.axisLabels.historicalValue")}`,
        data: isEmissionTable
          ? emissionSummary?.map(({ dailyEmission, date }) => ({
              y: dailyEmission,
              x: date,
            }))
          : costSummary?.map(({ dailyCost, date }) => ({
              y: dailyCost,
              x: date,
            })),
        borderWidth: 1,
        borderColor: colors.blue3,
        tension: 0.4,
        pointStyle: "circle",
        pointBackgroundColor: colors.red1,
        backgroundColor: colors.red1,
        pointBorderColor: colors.red1,
        pointRadius: 5,
      },
      {
        data: [
          {
            x: isEmissionTable
              ? emissionSummary[emissionSummary.length - 1].date
              : costSummary[costSummary.length - 1].date,
            y: isEmissionTable
              ? emissionSummary[emissionSummary.length - 1].dailyEmission
              : costSummary[costSummary.length - 1].dailyCost,
          },
          {
            x: isEmissionTable
              ? emissionPrediction[0].date
              : costPrediction[0].date,
            y: isEmissionTable
              ? emissionPrediction[0].emissionPrediction
              : costPrediction[0].costPrediction,
          },
        ],
        borderWidth: 1,
        borderColor: colors.green2,
        tension: 0.4,

        pointBackgroundColor: colors.yellow1,
        pointBorderColor: colors.yellow1,
        pointRadius: 0,
        segment: {
          borderDash: [6],
        },
      },
      {
        label: `${t("proPlan.axisLabels.prediction")}`,
        data: isEmissionTable
          ? emissionPrediction?.map(({ emissionPrediction, date }) => ({
              y: emissionPrediction,
              x: date,
            }))
          : costPrediction?.map(({ costPrediction, date }) => ({
              x: date,
              y: costPrediction,
            })),
        borderWidth: 1,
        borderColor: colors.green2,
        tension: 0.4,
        pointStyle: "triangle",
        backgroundColor: colors.yellow1,
        pointBackgroundColor: colors.yellow1,
        pointBorderColor: colors.yellow1,
        pointRadius: 5,
        segment: {
          borderDash: [6],
        },
      },
    ],
  };

  const lineChartConfig = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: { usePointStyle: true, pointStyle: "rectRounded" },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: `${t("proPlan.axisLabels.date")}`,
        },
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: isEmissionTable ? "kg CO2-eq" : "USD",
        },

        grid: {
          lineWidth: 0,
        },
      },
    },
  };

  const [selectedDateRange, setSelectedDateRange] = useState<any | null>([
    availableDates,
  ]);

  useEffect(() => {
    setEmissionSummary(
      proPlanResults?.emissionSummary?.filter(
        ({ date }) =>
          convertDateToUTC(new Date(date)) >= selectedDateRange[0].startDate &&
          convertDateToUTC(new Date(date)) <= selectedDateRange[0].endDate
      )
    );
    setCostSummary(
      proPlanResults?.costSummary?.filter(
        ({ date }) =>
          convertDateToUTC(new Date(date)) >= selectedDateRange[0].startDate &&
          convertDateToUTC(new Date(date)) <= selectedDateRange[0].endDate
      )
    );
  }, [selectedDateRange]);

  const getPredictions = () => (
    <div className={styles.mainContainer}>
      <SelectableTabs
        labels={[t("proPlan.tabs.emissions"), t("proPlan.tabs.costs")]}
        onSelect={setSelectedTab}
        selectedIndex={selectedTab}
      />
      <div className={styles.innerContainer}>
        <div className={styles.graphContainer}>
          <Line data={predictionData} options={lineChartConfig} />
        </div>
        <div className={styles.datePicker}>
          <span className={styles.pickerInstructions}>
            {t("proPlan.selectRange")}
          </span>
          <DateRangePicker
            onChange={(item) => setSelectedDateRange([item.selection])}
            moveRangeOnFirstSelection={false}
            editableDateInputs={true}
            showMonthAndYearPickers={false}
            staticRanges={[]}
            inputRanges={[]}
            ranges={selectedDateRange}
            rangeColors={[colors.green4]}
            shownDate={availableDates.startDate}
            minDate={availableDates.startDate}
            maxDate={availableDates.endDate}
          />
        </div>
      </div>
    </div>
  );
  return (
    <PungoDemoAccordion
      title={t("proPlan.labels.prediction")}
      content={getPredictions()}
      isPro
    />
  );
};

export default PredictionSection;

import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";


import PungoDemoAccordion from "../../../../pungo-ui/PungoDemoAccordion";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PungoDemoResultsCard from "../../../../pungo-ui/PungoDemoResultsCard/index";
import cost from "../../../../assets/AucaImages/Cost.svg";
import optimizedCost from "../../../../assets/AucaImages/OptimizedCost.svg";
import carbonEmissions from "../../../../assets/AucaImages/carbonEmissions.svg";
import reducedCarbonEmissions from "../../../../assets/AucaImages/reducedCarbonEmissions.svg";
import PungoTable from "pungo-ui/PungoTable";
import DemoResultsDoughnut from "pungo-ui/PungoResultsDoughnut";
import PungoButton from "pungo-ui/PungoButton";
import { selectResults } from "store/slices/plan";

import colors from "../../../../styles/export.module.scss";
import styles from "./index.module.scss";
import classNames from "classnames";

const ResultsSection: React.FC = () => {
  const { t, i18n } = useTranslation();

  const {
    totalEmissions,
    totalEnergyCost,
    energySourceResults,
    emissionsIntensity,
    emissionsIntensityUnit,
    energyIntensity,
    energyIntensityUnit,
    emissionsToReduce,
    optimisedCosts,
    optimisedEmissions,
    emissionsToReduceTreeEquivalence,
    costToReduce,
  } = useSelector(selectResults);

  const [menu, setMenu] = useState("emissions");
  const [chartType, setChartTyoe] = useState("emissions");
  const isSpanishLanguageSelected = i18n.language === "es";

  const getTreeEquivalenceMessage = () => {
    return (
      <div className={styles.treeEquivalenceMessage}>
        {t("DemoPage.ResultsSection.emissionReduction")}
        <span className={styles.accents}>
          &nbsp;{emissionsToReduceTreeEquivalence}&nbsp;
          {t("DemoPage.ResultsSection.trees")}
        </span>
      </div>
    );
  };

  const getEmptyState = () => (
    <>
      <div className={styles.emptyState}>
        {t("DemoPage.ResultsSection.emptyState")}
      </div>
    </>
  );
  const getResultMesssage = () => {
    return (
      <div className={styles.message}>
        <Trans>{t("DemoPage.ResultsSection.resultMessage")}</Trans>
      </div>
    );
  };

  const labelsForTable = [
    t("DemoPage.ResultsSection.energySources"),
    t("DemoPage.ResultsSection.emissionsKg"),
    t("DemoPage.ResultsSection.emissionsScope"),
    t("DemoPage.ResultsSection.energyCost"),
    t("DemoPage.ResultsSection.energyContent"),
  ];

  const valuesForTable = energySourceResults.map(
    ({
      emission,
      emissionScopeEs,
      emissionScopeEn,
      energyCost,
      energyContent,
      nameEs,
      nameEn,
    }) =>
      isSpanishLanguageSelected
        ? [nameEs, emission, emissionScopeEs, energyContent, energyCost]
        : [nameEn, emission, emissionScopeEn, energyContent, energyCost]
  );

  const getTotalCostsContent = () => (
    <>
      <div>
        <div className={styles.results}>
          <div className={styles.resultCard}>
            <PungoDemoResultsCard
              description={t("DemoPage.ResultsSection.totalCarbonEmissions")}
              result={`${totalEmissions} kg CO2-eq`}
              color={colors.red1}
            />
          </div>
          <div className={styles.resultCard}>
            <PungoDemoResultsCard
              description={t("DemoPage.ResultsSection.totalEnergyCost")}
              result={`${totalEnergyCost.toFixed(2)} USD`}
              color={colors.red1}
            />
          </div>
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div className={styles.tableContainer}>
            <PungoTable values={valuesForTable} labels={labelsForTable} />
          </div>
        </div>
        <div className={styles.chartContainer}>
          <div className={styles.chartMenuContainer}>
            <div className={styles.chartMenuButtonContainer}>
              <button
                className={styles.chartButton}
                onClick={() => setChartTyoe("emissions")}
              >
                {t("DemoPage.ResultsSection.emissionsContributionMenu")}
              </button>
              {chartType === "emissions" && (
                <div className={styles.underline} />
              )}
            </div>
            <div className={styles.chartMenuButtonContainer}>
              <button
                className={styles.chartButton}
                onClick={() => setChartTyoe("costs")}
              >
                {t("DemoPage.ResultsSection.energyCostContributionMenu")}
              </button>
              {chartType === "costs" && <div className={styles.underline} />}
            </div>
          </div>
          <div className={styles.chart}>
            <DemoResultsDoughnut
              resultsData={energySourceResults}
              chartType={
                chartType === "emissions" ? "CarbonEmissions" : "EnergyCost"
              }
            />
          </div>
        </div>
      </div>
    </>
  );
  const getOptimizationContent = () => (
    <>
      <div>
        <div className={styles.buttonContainer}>
          <PungoButton
            classNames={classNames(styles.button, {
              [styles.selectedMenu]: menu === "emissions",
            })}
            label={`${t("DemoPage.ResultsSection.emissions")}`}
            onClick={() => setMenu("emissions")}
          />
          <PungoButton
            classNames={classNames(styles.button, {
              [styles.selectedMenu]: menu === "costs",
            })}
            label={`${t("DemoPage.ResultsSection.costs")}`}
            onClick={() => setMenu("costs")}
          />
        </div>
        <Divider className={styles.divider} />

        {menu === "costs" ? (
          <div className={styles.results}>
            <div className={styles.resultCard}>
              <PungoDemoResultsCard
                description={t("DemoPage.OptimizationSection.currentCosts")}
                result={`${totalEnergyCost.toFixed(2)} USD`}
                color={colors.red1}
                image={cost}
              />
            </div>
            <div className={styles.resultCard}>
              <PungoDemoResultsCard
                description={t("DemoPage.OptimizationSection.optimizedCosts")}
                result={`${optimisedCosts.toFixed(2)} USD`}
                color={colors.green1}
                image={optimizedCost}
                reducedCost={`${costToReduce.toFixed(2)} USD`}
              />
            </div>
          </div>
        ) : (
          <div className={styles.results}>
            <div className={styles.resultCard}>
              <PungoDemoResultsCard
                description={t(
                  "DemoPage.OptimizationSection.currentCarbonEmissions"
                )}
                result={`${totalEmissions.toFixed(2)} kg CO2-eq`}
                color={colors.red1}
                image={carbonEmissions}
              />
            </div>
            <div className={styles.resultCard}>
              <PungoDemoResultsCard
                description={t(
                  "DemoPage.OptimizationSection.optimizedCarbonEmissions"
                )}
                result={`${optimisedEmissions.toFixed(2)} kg CO2-eq`}
                color={colors.green1}
                image={reducedCarbonEmissions}
                reducedEmissions={`${emissionsToReduce} kg CO2-eq`}
              />
            </div>
          </div>
        )}

        <div className={styles.resultMessage}>
          {getTreeEquivalenceMessage()}
        </div>
        <div className={styles.resultMessage}>{getResultMesssage()}</div>
      </div>
    </>
  );
  const getIndicatorsContent = () => (
    <>
      <div className={styles.results}>
        <div className={styles.resultCard}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.emissionsIntensity")}
            result={`${emissionsIntensity.toFixed(
              2
            )} ${emissionsIntensityUnit}`}
            color={colors.red1}
          />
        </div>
        <div className={styles.resultCard}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.energyIntensity")}
            result={`${energyIntensity.toFixed(2)} ${energyIntensityUnit}`}
            color={colors.red1}
          />
        </div>
      </div>
    </>
  );
  return (
    <PungoSectionCard title={t("DemoPage.ResultsSection.results")}>
      {!totalEmissions ? (
        getEmptyState()
      ) : (
        <div className={styles.container}>
          <div className={styles.resultsContainer}>
            <PungoDemoAccordion
              title={t("DemoPage.ResultsSection.totalCosts")}
              content={getTotalCostsContent()}
            />
          </div>
          <br></br>
          <div className={styles.resultsContainer}>
            <PungoDemoAccordion
              title={t("DemoPage.ResultsSection.indicatorsPerUnit")}
              content={getIndicatorsContent()}
            />
          </div>
          <br></br>
          <div className={styles.resultsContainer}>
            <PungoDemoAccordion
              title={t("DemoPage.OptimizationSection.optimization")}
              content={getOptimizationContent()}
            />
          </div>
        </div>
      )}
    </PungoSectionCard>
  );
};

export default ResultsSection;

import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { IEnergySourceResult } from "store/slices/plan";
import { useTranslation } from "react-i18next";
import ColorScale from "color-scales";
import colors from "../../styles/export.module.scss";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { chartConfig } from "./utils";

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);
interface IDemoResultsDoughnut {
  resultsData: IEnergySourceResult[];
  chartType: string;
}

const DemoResultsDoughnut = (props: IDemoResultsDoughnut) => {
  const { resultsData, chartType } = props;
  const { i18n } = useTranslation();
  const language = i18n.language;
  const isSpanishSelected = language === "es";

  const getBackgroundColors = (array: any, colors: string[]) => {
    const length = array?.length || 1;
    const colorScale = new ColorScale(
      0,
      length,
      colors.map((color) => color)
    );
    const backgroundColors = [];
    for (let index = 0; index < length; index += 1) {
      const newColor = colorScale.getColor(index + 1).toHexString();
      backgroundColors.push(newColor);
    }
    return backgroundColors;
  };
  const totalCarbonEmissions = resultsData.reduce((a, b) => a + b.emission, 0);
  const totalEnergyCost = resultsData.reduce((a, b) => a + b.energyCost, 0);

  const chartData =
    chartType === "CarbonEmissions"
      ? resultsData.map((result) =>
          ((result.emission / totalCarbonEmissions) * 100).toFixed(2)
        )
      : resultsData.map((result) =>
          ((result.energyCost / totalEnergyCost) * 100).toFixed(2)
        );

  const chartLabels = isSpanishSelected
    ? resultsData.map((item) => item.nameEs)
    : resultsData.map((item) => item.nameEn);

  const backgroundColors = getBackgroundColors(resultsData, [
    colors.red1,
    colors.green1,
    colors.blue3,
  ]);

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: "",
        data: chartData,
        backgroundColor: backgroundColors,
        borderWidth: 0,
        hoverOffset: 1,
      },
    ],
  };

  const config = chartConfig(data);
  config.options.plugins.title.display = false;
  return <Doughnut {...data} {...config} />;
};
export default DemoResultsDoughnut;

import React, { useState } from "react";
import { Slider } from "@mui/material";

import styles from "./index.module.scss";
import PungoDemoInput from "pungo-ui/PungoDemoInput";

export interface IEnergyInfo {
  id: number;
  value: number;
}
interface PungoSliderProps {
  id: number;
  description: string;
  minValue: number;
  maxValue: number;
  defaultValue: number;
  setSelectedValue: ({ id, value }: IEnergyInfo) => void;
  sliderValue?: number;
  inputValue?: number;
}
const PungoSlider: React.FC<PungoSliderProps> = (props) => {
  const {
    id,
    description,
    minValue = 0,
    maxValue = 100,
    defaultValue,
    setSelectedValue,
  } = props;
  const [sliderValue, setSliderValue] = useState<number>(defaultValue);
  const marks = [
    {
      value: minValue,
      label: minValue,
    },
    {
      value: maxValue,
      label: maxValue,
    },
  ];

  const handleChangeSliderValue = (newValue: any, type: string) => {
    if (type === "slider") {
      const newSliderValue = Number(newValue.target.value);
      setSelectedValue({ id: id, value: newSliderValue });
      setSliderValue(newSliderValue);
    } else {
      const newInputValue = Number(newValue);
      setSelectedValue({ id: id, value: newInputValue });
      setSliderValue(newInputValue);
    }
  };

  return (
    <div id={id.toString()} className={styles.container}>
      <div className={styles.description}>{description}</div>
      <div className={styles.sliderContainer}>
        <Slider
          step={0.5}
          min={minValue}
          max={maxValue}
          marks={marks}
          value={sliderValue}
          color={"primary"}
          valueLabelDisplay="on"
          onChange={(e) => handleChangeSliderValue(e, "slider")}
          track={false}
        />
      </div>
      <div className={styles.sliderValue}>
        <PungoDemoInput
          type={"number"}
          value={sliderValue}
          onChangeNumber={(e) => handleChangeSliderValue(e, "input")}
          height="25px"
          width="80px"
        />
      </div>
    </div>
  );
};

export default PungoSlider;

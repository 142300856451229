import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

import {
  ICostSummary,
  IEmissionSummary,
  selectProPlanResults,
} from "store/slices/plan";

import PungoDemoResultsCard from "pungo-ui/PungoDemoResultsCard";
import PungoDemoAccordion from "pungo-ui/PungoDemoAccordion";
import { convertDateToUTC } from "../utils";
import SelectableTabs from "../../common/SelectableTabs";
import PungoTable from "pungo-ui/PungoTable";

import colors from "styles/export.module.scss";
import styles from "./index.module.scss";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const SummarySection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishLanguageSelected = i18n.language === "es";
  const proPlanResults = useSelector(selectProPlanResults);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [emissionSummary, setEmissionSummary] = useState<IEmissionSummary[]>(
    proPlanResults?.emissionSummary
  );
  const [costSummary, setCostSummary] = useState<ICostSummary[]>(
    proPlanResults?.costSummary
  );

  const isEmissionTable = selectedTab === 0;

  const availableDates = {
    startDate:
      convertDateToUTC(new Date(proPlanResults?.emissionSummary[0].date)) ||
      new Date(),
    endDate:
      convertDateToUTC(
        new Date(
          proPlanResults?.emissionSummary[
            proPlanResults?.emissionSummary.length - 1
          ].date
        )
      ) || new Date(),
    key: "selection",
  };

  const emissionSummaryData = {
    labels: isEmissionTable
      ? emissionSummary?.map(({ date }) => date)
      : costSummary?.map(({ date }) => date),
    datasets: [
      {
        data: isEmissionTable
          ? emissionSummary?.map(({ dailyEmission }) => dailyEmission)
          : costSummary?.map(({ dailyCost }) => dailyCost),
        borderWidth: 1,
        borderColor: colors.blue3,
        tension: 0.4,
        pointStyle: "circle",
        pointBackgroundColor: colors.red1,
        pointBorderColor: colors.red1,
        pointRadius: 4,
      },
    ],
  };

  const LineChartConfig = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: `${t("proPlan.axisLabels.date")}`,
        },
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: isEmissionTable ? "kg CO2-eq" : "USD",
        },
        grid: {
          lineWidth: 0,
        },
      },
    },
  };

  const [selectedDateRange, setSelectedDateRange] = useState<any | null>([
    availableDates,
  ]);

  useEffect(() => {
    setEmissionSummary(
      proPlanResults?.emissionSummary?.filter(
        ({ date }) =>
          convertDateToUTC(new Date(date)) >= selectedDateRange[0].startDate &&
          convertDateToUTC(new Date(date)) <= selectedDateRange[0].endDate
      )
    );
    setCostSummary(
      proPlanResults?.costSummary?.filter(
        ({ date }) =>
          convertDateToUTC(new Date(date)) >= selectedDateRange[0].startDate &&
          convertDateToUTC(new Date(date)) <= selectedDateRange[0].endDate
      )
    );
  }, [selectedDateRange, proPlanResults]);

  const labelsForTable = [
    t("proPlan.tableLabel.date"),
    t("proPlan.tableLabel.idProcess"),
    t("proPlan.tableLabel.idEquipment"),
    t("proPlan.tableLabel.energySource"),
    t("proPlan.tableLabel.emissions"),
    t("proPlan.tableLabel.scope"),
    t("proPlan.tableLabel.energyCost"),
    t("proPlan.tableLabel.energyContent"),
  ];

  const valuesForTable = proPlanResults?.emission.map(
    ({
      date,
      energySourceEn,
      energySourceEs,
      equipmentId,
      processId,
      emissionScopeEn,
      emissionScopeEs,
      emission,
      energyContent,
      energyCost,
    }) =>
      isSpanishLanguageSelected
        ? [
            date,
            processId,
            equipmentId,
            energySourceEs,
            emission,
            emissionScopeEs,
            energyCost,
            energyContent,
          ]
        : [
            date,
            processId,
            equipmentId,
            energySourceEn,
            emission,
            emissionScopeEn,
            energyCost,
            energyContent,
          ]
  );

  const getTotalEnergyResults = () => (
    <div className={styles.mainContainer}>
      <SelectableTabs
        labels={[t("proPlan.tabs.emissions"), t("proPlan.tabs.costs")]}
        onSelect={setSelectedTab}
        selectedIndex={selectedTab}
      />
      <div className={styles.innerContainer}>
        <div className={styles.upperContainer}>
          <Line data={emissionSummaryData} options={LineChartConfig} />
        </div>
        <div className={styles.middleSection}>
          <div className={styles.result}>
            <PungoDemoResultsCard
              description={
                isEmissionTable
                  ? t("proPlan.resultLabel.emissions")
                  : t("proPlan.resultLabel.costs")
              }
              result={
                isEmissionTable
                  ? `${emissionSummary
                      .reduce(
                        (acc, currentValue) => acc + currentValue.dailyEmission,
                        0
                      )
                      .toFixed(1)} kg CO2-eq`
                  : `${costSummary
                      .reduce(
                        (acc, currentValue) => acc + currentValue.dailyCost,
                        0
                      )
                      .toFixed(1)} USD`
              }
              color={colors.red1}
            />
          </div>
          <div className={styles.datePicker}>
            <span className={styles.pickerInstructions}>
              {t("proPlan.selectRange")}
            </span>
            <DateRangePicker
              onChange={(item) => setSelectedDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              editableDateInputs={true}
              showMonthAndYearPickers={false}
              staticRanges={[]}
              inputRanges={[]}
              ranges={selectedDateRange}
              rangeColors={[colors.green4]}
              shownDate={availableDates.startDate}
              minDate={availableDates.startDate}
              maxDate={availableDates.endDate}
            />
          </div>
        </div>
        <div className={styles.table}>
          <PungoTable
            values={valuesForTable}
            labels={labelsForTable}
            height={300}
          />
        </div>
  
      </div>
    </div>
  );
  return (
    <PungoDemoAccordion
      title={t("proPlan.labels.emissionsAndCosts")}
      content={getTotalEnergyResults()}
      isPro
    />
  );
};

export default SummarySection;

import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

interface AucaResultsCardProps {
  id?: string;
  description: string;
  result?: number | string;
  color?: string;
  image?: string;
  reducedCost?: string;
  reducedEmissions?: string;
}

const AucaResultsCard: React.FC<AucaResultsCardProps> = (props) => {
  const {
    id,
    description,
    result,
    color,
    image,
    reducedCost,
    reducedEmissions,
  } = props;
  const { t } = useTranslation();
  return (
    <div id={id} className={styles.container}>
      <div style={{ color: color }} className={styles.description}>
        {description}
      </div>
      <div
        style={{ backgroundColor: color, color: "#FFF" }}
        className={styles.result}
      >
        {result}
      </div>
      <div style={{ color: color }} className={styles.reduced}>
        {reducedCost ? (
          <div>
            {t("DemoPage.ResultsSection.reducedCost", {
              reducedCost: reducedCost,
            })}
          </div>
        ) : (
          reducedEmissions && (
            <div>
              {t("DemoPage.ResultsSection.reducedEmissions", {
                reducedEmissions: reducedEmissions,
              })}
            </div>
          )
        )}
      </div>
      {image && (
        <div>
          <img className={styles.image} src={image} alt="" />
        </div>
      )}
    </div>
  );
};

export default AucaResultsCard;

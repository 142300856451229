import colors from "../../styles/export.module.scss";

export const chartConfig = (data: any) => ({
  type: "doughnut",
  data,
  options: {
    maintainAspectRatio: false,
    width: "100%",
    heigth: "100%",
    responsive: true,
    plugins: {
      legend: {
        position: "right" as const,
        labels: {
          pointStyle: "rect",
          usePointStyle: true,
        },
        display: true,
        width: "20px",
        marginLeft: "100px",
      },
      title: {
        display: true,
        text: "Chart.js Doughnut Chart",
      },
      datalabels: {
        color: colors.white1,
        display: true,
        font: {
          weight: "bolder" as const,
        },
        formatter: function (value: number) {
          return `${value} %`;
        },
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = `${context.formattedValue} %` || "";
            return label;
          },
        },
      },
    },
  },
});

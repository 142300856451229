import React from "react";
import PungoPlanByTransactionCard from "pungo-ui/PungoPlanByTransactionCard";
import PungoPlanByTransactionCardMobile from "pungo-ui/PungoPlanByTransactionCardMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import breakpoints from "../../styles/export.module.scss";
import json2mq from "json2mq";
import PungoCustomizedCarousel from "pungo-ui/PungoCustomizedCarousel";
import styles from "./index.module.scss";

interface PungoPlanByTransactionCardProps {
  id: string;
  name: string;
  description: string;
  cost: string;
  commentToCost: string;
  frequency: string;
  link: string;
  benefits: string[];
}

interface MyComponentProps {
  data: PungoPlanByTransactionCardProps[];
  period: string;
  handleClick: any;
}

const PungoPlanByTransactionGroup: React.FC<MyComponentProps> = ({ data, period, handleClick }) => {
  const desktopView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.lg,
    })
  );

  const getPlanCards = () =>
    data.map(({ id, name, description, cost, commentToCost, frequency, link, benefits }) => (
      <div key={id} className={styles.planItem}>
        <PungoPlanByTransactionCard
          id={id}
          name={name}
          description={description}
          cost={cost}
          commentToCost={commentToCost}
          frequency={frequency}
          link={link}
          benefits={benefits}
          handleClick={handleClick}
        />
      </div>
    ));
  
    const getPlanCardsMobile = () =>
      data.map(({ id, name, description, cost, commentToCost, frequency, link, benefits }) => (
        <div key={id} className={styles.planItem}>
          <PungoPlanByTransactionCardMobile
            id={id}
            name={name}
            description={description}
            cost={cost}
            commentToCost={commentToCost}
            frequency={frequency}
            link={link}
            benefits={benefits}
            handleClick={handleClick}
          />
        </div>
      ));

  return (
    <div className={styles.container}>
      <div className={styles.planContainer}>
        {desktopView ? (
          getPlanCards()
        ) : (
          <div className={styles.carouselContainer}>
            <PungoCustomizedCarousel slides={getPlanCardsMobile()} height={400} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PungoPlanByTransactionGroup;

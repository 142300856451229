import {
  loginInUser,
  logOutUser,
  saveLoginEmail,
  saveUserName,
  saveAccountStatus,
  saveRole,
  saveSurveyStatus,
  setLoginTime,
  cleanUser,
} from "store/slices/auth";
import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import { AppDispatch } from "../index";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import {
  displaySnackbarAPIError,
  displaySnackbarAPISuccess,
  getSnackbarError,
} from "./utils";
import { addAPIcall, completeAPIcall, clearAPIcalls } from "store/slices/api";
import moment from "moment";
import { clearSurvey, setAnswerLoader } from "store/slices/survey";
import { store } from "../../store";
import { clearQuestions } from "store/slices/question";
import { clearUserManagement } from "store/slices/user";
import {
  clearProPlanResults,
  clearResults,
  clearSources,
  saveSelectedPlan,
  setFetchedMockedDataStatus,
} from "store/slices/plan";
import { clearProfileManagement } from "store/slices/profile";

interface ILoginSession {
  username: string;
  password: string;
}
interface IPasswordChange {
  oldPassword: string;
  newPassword: string;
}
export interface ILogoutSession {
  id_token: string;
}
export interface IRefreshSession {
  refresh_token: string;
}
export interface ITokenInfo {
  email: string;
  email_verified: boolean;
  name: string;
  realm_access: { roles: string[] };
  survey_completed: boolean;
}

const sessionURL = getBaseUrlForAPI("session");
const passwordChangeURL = getBaseUrlForAPI("passwordChange");

export const logInSession =
  (userInfo: ILoginSession, handleRedirect?: () => void) =>
  (dispatch: AppDispatch): void => {
    const id = `logInSession-${uuidv4()}`;
    dispatch(addAPIcall(id));
    axiosInstance
      .post(`${sessionURL}/login`, userInfo)
      .then(({ data }) => {
        dispatch(loginInUser(data));
        const extraInfo: ITokenInfo = jwt_decode(data.access_token);
        dispatch(saveLoginEmail(extraInfo?.email));
        dispatch(saveUserName(extraInfo?.name));
        dispatch(saveAccountStatus(extraInfo?.email_verified));
        dispatch(saveSurveyStatus(extraInfo?.survey_completed));
        dispatch(saveRole(extraInfo?.realm_access?.roles));
        dispatch(displaySnackbarAPISuccess("successMessage.login"));
        dispatch(setLoginTime(moment().format()));
      })
      .then(() => {
        dispatch(clearResults());
        if (handleRedirect) {
          handleRedirect();
        }
      })
      .catch((error) => {
        getSnackbarError(error);
      })
      .finally(() => {
        dispatch(completeAPIcall(id));
      });
  };

export const logOutSession =
  (id_token: ILogoutSession) =>
  (dispatch: AppDispatch): void => {
    const id = `logOutSession-${uuidv4()}`;
    dispatch(addAPIcall(id));
    axiosInstance
      .post(`${sessionURL}/logout`, id_token)
      .then(() => {
        dispatch(setAnswerLoader(false));
        dispatch(logOutUser());
        dispatch(clearQuestions());
        dispatch(clearUserManagement());
        dispatch(displaySnackbarAPIError("expiredSession")); 
        dispatch(clearSurvey());
        dispatch(clearSources());
        dispatch(clearResults());
        dispatch(clearProfileManagement());
        dispatch(clearAPIcalls());
        dispatch(clearProPlanResults());
        dispatch(saveSelectedPlan("free"));
        dispatch(setFetchedMockedDataStatus(false));
      })
      .catch(() => {
        dispatch(setAnswerLoader(false));
        dispatch(logOutUser());
        dispatch(clearQuestions());
        dispatch(clearUserManagement());
        dispatch(displaySnackbarAPIError("expiredSession")); 
        dispatch(clearSurvey());
        dispatch(clearSources());
        dispatch(clearResults());
        dispatch(clearProfileManagement());
        dispatch(clearAPIcalls());
        dispatch(clearProPlanResults());
        dispatch(saveSelectedPlan("free"));
        dispatch(setFetchedMockedDataStatus(false));
      })
      .finally(() => {
        dispatch(completeAPIcall(id));
      });
  };

export const refresh =
  (refreshToken: IRefreshSession, originalRequest?: () => void) =>
  (dispatch: AppDispatch): void => {
    const id = `refreshSession-${uuidv4()}`;
    dispatch(cleanUser());
    dispatch(addAPIcall(id));
    axiosInstance
      .post(`${sessionURL}/refresh`, refreshToken)
      .then(({ data }) => {
        dispatch(loginInUser(data));
        const extraInfo: ITokenInfo = jwt_decode(data.access_token);
        dispatch(saveLoginEmail(extraInfo?.email));
        dispatch(saveUserName(extraInfo?.name));
        dispatch(saveAccountStatus(extraInfo?.email_verified));
        dispatch(saveSurveyStatus(extraInfo?.survey_completed));
        dispatch(saveRole(extraInfo?.realm_access?.roles));
        dispatch(setLoginTime(moment().format()));
        if (originalRequest) {
          originalRequest();
        }
      })
      .catch((error) => {
        getSnackbarError(error);
      })
      .finally(() => {
        dispatch(completeAPIcall(id));
      });
  };

export const changePasswordFromSession =
  (passwords: IPasswordChange) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `changePasswordFromSession-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${passwordChangeURL}`, passwords)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.changePassword"));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

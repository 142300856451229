import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import { AppDispatch } from "../index";
import { store } from "../../store";
import {
  saveProPlanResults,
  saveResults,
  saveSources,
  setFetchedMockedDataStatus,
} from "store/slices/plan";
import { v4 as uuidv4 } from "uuid";
import { addAPIcall, completeAPIcall } from "store/slices/api";
import moment from "moment";
import { logOutSession, refresh } from "./authActions";
import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";
import { t } from "i18next";
import {
  setSubscriptionPremiumPlan,
  setSubscriptionProPlan,
  setSubscriptionStandarPlan,
} from "store/slices/auth";

const calculateEnergySourceURL = getBaseUrlForAPI("calculateEnergySource");
const calculateURL = getBaseUrlForAPI("calculate");
const mockedDataURL = getBaseUrlForAPI("mockedDataForProPlan");
const calculationFile = getBaseUrlForAPI("calculationFile");
const subscribeURL = getBaseUrlForAPI("subscribePlan");
const subscribeRequestMessage = t("successMessage.subscribeMessage");
interface IEnergySource {
  id: number;
  value: number;
}
interface IEnergyRequest {
  energySources: IEnergySource[];
  productionValue: number;
  productionUnit: string;
}

export const getCalculateEnergySources =
  () =>
  (dispatch: AppDispatch): any => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `getCalculateEnergySources-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${calculateEnergySourceURL}`)
        .then(({ data }) => {
          dispatch(saveSources(data));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const calculateEmissions =
  (calculateInfo: IEnergyRequest) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `calculateEmissions-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${calculateURL}`, calculateInfo)
        .then(({ data }) => {
          dispatch(saveResults(data));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const subscribeSimple =
  () =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `subscribeSimple-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${subscribeURL}subscribe-simple`)
        .then(() => {
          dispatch(displaySnackbarAPISuccess(subscribeRequestMessage));
          dispatch(setSubscriptionStandarPlan(true));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };
export const subscribePro =
  () =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `subscribePro-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${subscribeURL}subscribe-pro`)
        .then(() => {
          dispatch(displaySnackbarAPISuccess(subscribeRequestMessage));
          dispatch(setSubscriptionProPlan(true));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const subscribePremium =
  () =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `subscribePremium-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${subscribeURL}subscribe-premium`)
        .then(() => {
          dispatch(displaySnackbarAPISuccess(subscribeRequestMessage));
          dispatch(setSubscriptionPremiumPlan(true));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const getMockedDataForProPlan =
  () =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `getMockedDataForProPlan-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${mockedDataURL}`)
        .then(({ data }) => {
          dispatch(saveProPlanResults(data));
          dispatch(setFetchedMockedDataStatus(true));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const downloadCalculationTemplate =
  () =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `downloadCalculationTemplate-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${calculationFile}`, { responseType: "blob" })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            .split("filename=")[1]
            .split(".")[0];

          const { data } = response;
          const url = URL.createObjectURL(data);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${filename.substring(1)}.xlsx`;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          a.remove();
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };
export const uploadCalculationTemplate =
  (uploadedFile: FormData) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `uploadCalculationTemplate-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(
          calculationFile,
          uploadedFile

        )
        .then(({ data }) => {
          dispatch(saveProPlanResults(data));
          dispatch(setFetchedMockedDataStatus(true));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

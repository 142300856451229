import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PungoDemoAccordion from "../../../../pungo-ui/PungoDemoAccordion";
import PungoTable from "pungo-ui/PungoTable";
import { selectProPlanResults } from "store/slices/plan";
import layoutImage from "../../../../assets/AucaImages/processLayout.svg";
import { selectUserRole } from "store/slices/auth";
import SelectableTabs from "../ResultsProSection/common/SelectableTabs";

import styles from "./index.module.scss";
import "../../../../styles/variables.scss";

const DataInputProSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishLanguageSelected = i18n.language === "es";

  const proPlanResults = useSelector(selectProPlanResults);
  const userRoles = useSelector(selectUserRole);
  const isFreeUser = userRoles?.includes("free_plan");

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const isEnergyConsumptionTableSelected = selectedTab === 0;

  const labelsForConsumptionTable = [
    t("proPlan.tableLabel.date"),
    t("proPlan.tableLabel.idProcess"),
    t("proPlan.tableLabel.idEquipment"),
    t("proPlan.tableLabel.energySource"),
    t("proPlan.tableLabel.consumption"),
    t("proPlan.tableLabel.unit"),
  ];

  const labelsForProductionTable = [
    t("proPlan.tableLabel.date"),
    t("proPlan.tableLabel.production"),
    t("proPlan.tableLabel.unit"),
  ];

  const valuesForProductionTable = proPlanResults?.production.map(
    ({ date, value, unit }) => [date, value, unit]
  );
  const valuesForConsumptionTable = proPlanResults?.energyConsumption.map(
    ({
      date,
      energySourceEn,
      energySourceEs,
      equipmentId,
      processId,
      unitsEn,
      unitsEs,
      consumption,
    }) =>
      isSpanishLanguageSelected
        ? [date, processId, equipmentId, energySourceEs, consumption, unitsEs]
        : [date, processId, equipmentId, energySourceEn, consumption, unitsEn]
  );

  const getEnergySourcesContent = () => (
    <img
      src={layoutImage}
      alt="Industrial process layout"
      className={styles.image}
    />
  );
  const getDailyProductionContent = () => (
    <div>
      <SelectableTabs
        labels={[
          t("proPlan.tabs.energyConsumption"),
          t("proPlan.tabs.plantProduction"),
        ]}
        onSelect={setSelectedTab}
        selectedIndex={selectedTab}
      />
      {isEnergyConsumptionTableSelected && (
        <PungoTable
          values={valuesForConsumptionTable}
          labels={labelsForConsumptionTable}
          height={300}
        />
      )}
      {!isEnergyConsumptionTableSelected && (
        <PungoTable
          values={valuesForProductionTable}
          labels={labelsForProductionTable}
          height={300}
        />
      )}
    </div>
  );

  return (
    <PungoSectionCard title={t("proPlan.section.dataInput")}>
      <div className={styles.container}>
        <div className={styles.accordionContainer}>
          {isFreeUser && (
            <PungoDemoAccordion
              title={t("proPlan.labels.industrialPlant")}
              content={getEnergySourcesContent()}
            />
          )}
        </div>
        <div className={styles.accordionContainer}>
          <PungoDemoAccordion
            title={t("proPlan.labels.energyConsumption")}
            content={getDailyProductionContent()}
            isPro
          />
        </div>
      </div>
    </PungoSectionCard>
  );
};

export default DataInputProSection;

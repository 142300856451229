import React, { useState } from "react";

import { useSelector } from "react-redux";
import {
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Trans, useTranslation } from "react-i18next";

import { selectProPlanResults } from "store/slices/plan";
import PungoDemoAccordion from "pungo-ui/PungoDemoAccordion";
import SelectableTabs from "../../common/SelectableTabs";

import styles from "./index.module.scss";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const ActionSection: React.FC = () => {
  const { t } = useTranslation();
  const proPlanResults = useSelector(selectProPlanResults);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const actions = proPlanResults?.actions || [];

  const getActions = () => (
    <div className={styles.mainContainer}>
      <SelectableTabs
        labels={actions.map(({ name }) => name)}
        onSelect={setSelectedTabIndex}
        selectedIndex={selectedTabIndex}
      />
      {actions[selectedTabIndex].notes && (
        <div className={styles.resultMessage}>
          <div className={styles.message}>
            <Trans>{actions[selectedTabIndex].notes}</Trans>
          </div>
        </div>
      )}
      <ol className={styles.actions}>
        {actions[selectedTabIndex].actions.map((action) => (
          <li className={styles.individualAction} key={action}>
            <Trans>{action}</Trans>
          </li>
        ))}
      </ol>
    </div>
  );
  return (
    <PungoDemoAccordion
      title={t("proPlan.labels.actions")}
      content={getActions()}
      isPro
    />
  );
};

export default ActionSection;

import React from "react";
import styles from "./index.module.scss";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { makeStyles } from "@material-ui/core/styles";
interface PungoAccordionProps {
  title: string;
  content?: React.ReactNode;
}

const useStyles = makeStyles({
  content: {
    justifyContent: "center",
  },
});
const PungoAccordion: React.FC<PungoAccordionProps> = (props) => {
  const { title, content } = props;
  const [expanded, setExpanded] = React.useState<string | boolean>(false);
  const classes = useStyles();
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{ content: classes.content }}
      >
        <div className={styles.titleContainer}>
          <div className={styles.accordionTitle}>{title}</div>
          <div className={styles.icon}>
            {expanded ? (
              <ExpandLessIcon fontSize="large" />
            ) : (
              <ExpandMoreIcon fontSize="large" />
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails> {content}</AccordionDetails>
    </Accordion>
  );
};

export default PungoAccordion;

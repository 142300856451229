import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Download, UploadFile } from "@mui/icons-material";

import ReactPlayer from "react-player/lazy";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";


import { useAppDispatch } from "app/hooks";
import PungoButton from "pungo-ui/PungoButton";
import { selectSelectedPlan } from "store/slices/plan";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import { selectUserRole } from "store/slices/auth";
import ProBadge from "components/common/ProBadge";
import {
  downloadCalculationTemplate,
  uploadCalculationTemplate,
} from "store/actions/planActions";
import PungoModal from "pungo-ui/PungoModal";

import styles from "./index.module.scss";
import breakpoints from "../../../../styles/export.module.scss";

const InstructionsSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedPlan = useSelector(selectSelectedPlan);
  const userRoles = useSelector(selectUserRole);

  const mobileViewForVideo = useMediaQuery(
    json2mq({
      minWidth: breakpoints.md,
    })
  );


  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [showNotAllowedFileModal, setShowNotAllowedFileModal] = useState(false);

  const isFreeUser = userRoles?.includes("free_plan");

  const getInstructionsContent = () => (
    <>
      <div className={styles.text}>
        <Trans>{t("DemoPage.InstructionsSection.steps")}</Trans>
      </div>

      {/* Video free plan tutorial */}

      <div className={styles.video}>
        <ReactPlayer
          url="https://d2ay24nheu7ro1.cloudfront.net/video_howto_plan_free_es_v2.mp4"
          width={!mobileViewForVideo ? 350 : 640}
          height={!mobileViewForVideo ? 197 : 360}
          light="https://d2ay24nheu7ro1.cloudfront.net/image_video_howto_plan_free_es_v2.png"
          controls
        />
      </div>
    </>

  );

  const clearFileInput = (ctrl: any) => {
    try {
      ctrl.value = null;
    } catch (ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  };

  const handleOnCloseModal = () => {
    setShowNotAllowedFileModal(false);
    clearFileInput(document.getElementById("file"));
    setUploadedFile(null);
  };

  useEffect(() => {
    if (uploadedFile) {
      const extension = uploadedFile.name.substring(
        uploadedFile.name.lastIndexOf(".") + 1
      );
      if (extension !== "xlsx") {
        setShowNotAllowedFileModal(true);
      }
    }
  }, [uploadedFile]);

  const onUploadFile = () => {
    if (uploadedFile) {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("filename", uploadedFile.name);

      dispatch(uploadCalculationTemplate(formData));
      setUploadedFile(null);
      clearFileInput(document.getElementById("file"));
    }
  };

  const getProInstructionsContent = () => (
    <>
      <div className={styles.text}>
        <Trans>{t("DemoPage.InstructionsSection.proSteps")}</Trans>
        <div className={styles.buttonSection}>
          <ProBadge />
          <PungoButton
            label={`${t("manageProfile.downloadFileButton")}`}
            type="text"
            onClick={() => dispatch(downloadCalculationTemplate())}
            color="secondary"
            icon={<Download />}
            disabled={isFreeUser}
          />
          <input
            type="file"
            id="file"
            onChange={(event: any) => setUploadedFile(event.target.files[0])}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            className={styles.fileInput}
            disabled={isFreeUser}
          />
          <PungoButton
            label={`${t("manageProfile.uploadFileButton")}`}
            type="text"
            onClick={onUploadFile}
            color="secondary"
            icon={<UploadFile />}
            disabled={isFreeUser || !uploadedFile}
          />
        </div>

      </div>



      {/* Video pro plan tutorial */}

      <div className={styles.video}>
        <ReactPlayer
          url="https://d2ay24nheu7ro1.cloudfront.net/video_howto_plan_pro_es_v2.mp4"
          width={!mobileViewForVideo ? 350 : 640}
          height={!mobileViewForVideo ? 197 : 360}
          light="https://d2ay24nheu7ro1.cloudfront.net/image_video_howto_plan_pro_es_v2.png"
          controls
        />
      </div>

    </>
  );

  const getDisclaimer = () => (
    <div className={styles.resultMessage}>
      <div className={styles.message}>
        <Trans>{t("DemoPage.disclaimer")}</Trans>
      </div>
    </div>
  );

  return (
    <PungoSectionCard title={t("DemoPage.InstructionsSection.instructions")}>
      <div className={styles.container}>
        {selectedPlan === "free"
          ? getInstructionsContent()
          : getProInstructionsContent()}
        {selectedPlan === "pro" && isFreeUser
          ? getDisclaimer()
          : ""}
      </div>
      <PungoModal
        open={showNotAllowedFileModal}
        classNames={styles.modal}
        title={`${t("admin.detailsLabel")}`}
        handleClose={handleOnCloseModal}
        primaryAction={{
          label: "Ok",
          onClick: handleOnCloseModal,
        }}
      >
        <div className={styles.modalChild}>{t("manageProfile.fileNotAllowed")}</div>
      </PungoModal>
    </PungoSectionCard>
  );
};

export default InstructionsSection;

import React, { useEffect, useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "app/hooks";
import { FormControlLabel, Switch } from "@mui/material";

import PungoInput from "pungo-ui/PungoInput";
import {
  selectEnergySources,
  selectFilteringResultPages,
} from "store/slices/plan";
import {
  createEnergy,
  deleteEnergy,
  editEnergy,
  getFilteredEnergySources,
} from "store/actions/energySourcesActions";
import PungoModal from "pungo-ui/PungoModal";
import AdminTable, { ITableColumn } from "../common/AdminTable";

import styles from "./index.module.scss";

const AdminEnergiesTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const energiesData = useSelector(selectEnergySources);
  const totalEnergyPages = useSelector(selectFilteringResultPages);
  const [selectedEnergyId, setSelectedEnergyId] = useState<
    number | undefined
  >();

  const [showModal, setShowModal] = useState(false);
  const [spanishName, setSpanishName] = useState("");
  const [englishName, setEnglishName] = useState("");
  const [spanishUnit, setSpanishUnit] = useState("");
  const [englishUnit, setEnglishUnit] = useState("");
  const [spanishScope, setSpanishScope] = useState("");
  const [englishScope, setEnglishScope] = useState("");

  const initialIdFilter = "";
  const initialStatusFilter = "ACTIVE";
  const initialMostCommonFilter = "all";
  const initialEnergyTypeFilter = "all";
  const initialUnitCostFilter = "";
  const initialNameEsFilter = "";
  const initialNameEnFilter = "";
  const initialUnitEsFilter = "";
  const initialUnitEnFilter = "";

  const [idFilter, setIdFilter] = useState(initialIdFilter);
  const [statusFilter, setStatusFilter] = useState(initialStatusFilter);
  const [mostCommonFilter, setMostCommonFilter] = useState(
    initialMostCommonFilter
  );
  const [energyTypeFilter, setEnergyTyperFilter] = useState(
    initialEnergyTypeFilter
  );
  const [unitCostFilter, setUnitCostFilter] = useState(initialUnitCostFilter);
  const [nameEsFilter, setNameEsFilter] = useState(initialNameEsFilter);
  const [nameEnFilter, setNameEnFilter] = useState(initialNameEnFilter);
  const [unitEsFilter, setUnitEsFilter] = useState(initialUnitEsFilter);
  const [unitEnFilter, setUnitEnFilter] = useState(initialUnitEnFilter);

  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [sortBy, setSortBy] = useState("id");
  const [sortType, setSortType] = useState("asc");

  const [defaultValueFilter, setDefaultValueFilter] = useState("");
  const [minValueFilter, setMinValueFilter] = useState("");
  const [maxValueFilter, setMaxValueFilter] = useState("");
  const [emissionFactorFilter, setEmissionFactorFilter] = useState("");
  const [emissionScopeEsFilter, setEmissionScopeEsFilter] = useState("");
  const [emissionScopeEnFilter, setEmissionScopeEnFilter] = useState("");
  const [demoOrderFilter, setDemoOrderFilter] = useState("");

  const [energyType, setEnergyType] = useState("");
  const [mostCommon, setMostCommon] = useState(false);
  const [cost, setCost] = useState(0);
  const [emissionFactor, setEmissionFactor] = useState(0);
  const [energyContent, setEnergyContent] = useState(0);
  const [defaultValue, setDefaultValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [demoOrder, setDemoOrder] = useState(99);
  const [pageSize, setPageSize] = useState(10);
  const [filteredEnergiesTimeoutId, setFilteredEnergiesTimeoutId] =
  useState(0);

  const filterStatusOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.active"), value: "ACTIVE" },
    { label: t("admin.filtering.removed"), value: "REMOVED" },
  ];

  const filterMostCommonOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.true"), value: "true" },
    { label: t("admin.filtering.false"), value: "false" },
  ];

  const filterTypeOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.liquid"), value: "LIQUID" },
    { label: t("admin.filtering.solid"), value: "SOLID" },
    { label: t("admin.filtering.electricity"), value: "ELECTRICITY" },
    { label: t("admin.filtering.gas"), value: "GAS" },
  ];

  const columnsForEnergiesTable: ITableColumn[] = [
    {
      id: "id",
      field: "id",
      headerName: "ID",
      sortable: false,
      minWidth: 150,
      filter: {
        name: "ID",
        type: "text",
        value: idFilter,
        setter: setIdFilter,
      },
    },
    {
      id: "status",
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.status"),
        type: "list",
        value: statusFilter,
        setter: setStatusFilter,
        options: filterStatusOptions,
      },
    },
    {
      id: "mostCommon",
      field: "mostCommon",
      headerName: "Most Common",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.mostCommon"),
        type: "list",
        value: mostCommonFilter,
        setter: setMostCommonFilter,
        options: filterMostCommonOptions,
      },
    },
    {
      id: "energyType",
      field: "energyType",
      headerName: "Type",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.type"),
        type: "list",
        value: energyTypeFilter,
        setter: setEnergyTyperFilter,
        options: filterTypeOptions,
      },
    },
    {
      id: "unitCost",
      field: "unitCost",
      headerName: "Cost",
      sortable: false,
      minWidth: 220,
      filter: {
        name: t("admin.filtering.unitCost"),
        type: "text",
        value: unitCostFilter,
        setter: setUnitCostFilter,
      },
    },
    {
      id: "nameEs",
      field: "nameEs",
      headerName: "Nombre",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.esName"),
        type: "text",
        value: nameEsFilter,
        setter: setNameEsFilter,
      },
    },
    {
      id: "nameEn",
      field: "nameEn",
      headerName: "Name",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.enName"),
        type: "text",
        value: nameEnFilter,
        setter: setNameEnFilter,
      },
    },
    {
      id: "unitsEs",
      field: "unitsEs",
      headerName: "Unidad",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.unitEs"),
        type: "text",
        value: unitEsFilter,
        setter: setUnitEsFilter,
      },
    },
    {
      id: "unitsEn",
      field: "unitsEn",
      headerName: "Unit",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.unitEn"),
        type: "text",
        value: unitEnFilter,
        setter: setUnitEnFilter,
      },
    },
    {
      id: "defaultValue",
      field: "defaultValue",
      headerName: "Default value",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.defaultValue"),
        type: "text",
        value: defaultValueFilter,
        setter: setDefaultValueFilter,
      },
    },
    {
      id: "minValue",
      field: "minValue",
      headerName: "Min value",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.minValue"),
        type: "text",
        value: minValueFilter,
        setter: setMinValueFilter,
      },
    },
    {
      id: "maxValue",
      field: "maxValue",
      headerName: "Max value",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.maxValue"),
        type: "text",
        value: maxValueFilter,
        setter: setMaxValueFilter,
      },
    },
    {
      id: "emissionFactor",
      field: "emissionFactor",
      headerName: "Emission factor",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.emissionFactor"),
        type: "text",
        value: emissionFactorFilter,
        setter: setEmissionFactorFilter,
      },
    },
    {
      id: "emissionScopeEs",
      field: "emissionScopeEs",
      headerName: "Alcance",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.emissionScopeEs"),
        type: "text",
        value: emissionScopeEsFilter,
        setter: setEmissionScopeEsFilter,
      },
    },
    {
      id: "emissionScopeEn",
      field: "emissionScopeEn",
      headerName: "Scope",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.emissionScopeEn"),
        type: "text",
        value: emissionScopeEnFilter,
        setter: setEmissionScopeEnFilter,
      },
    },
    {
      id: "demoOrder",
      field: "demoOrder",
      headerName: "Demo Order",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.demoOrder"),
        type: "text",
        value: demoOrderFilter,
        setter: setDemoOrderFilter,
      },
    },
  ];

  const energyToEdit = energiesData.find(({ id }) => id === selectedEnergyId);

  useEffect(() => {
    setSpanishName(energyToEdit?.nameEs || "");
    setEnglishName(energyToEdit?.nameEn || "");
    setSpanishUnit(energyToEdit?.unitsEs || "");
    setEnglishUnit(energyToEdit?.unitsEn || "");
    setSpanishScope(energyToEdit?.emissionScopeEs || "");
    setEnglishScope(energyToEdit?.emissionScopeEn || "");
    setEnergyType(energyToEdit?.energyType || "");
    setMostCommon(energyToEdit?.mostCommon || false);
    setCost(energyToEdit?.unitCost || 0);
    setEmissionFactor(energyToEdit?.emissionFactor || 0);
    setEnergyContent(energyToEdit?.energyContent || 0);
    setDefaultValue(energyToEdit?.defaultValue || 0);
    setMinValue(energyToEdit?.minValue || 0);
    setMaxValue(energyToEdit?.maxValue || 0);
    setDemoOrder(energyToEdit?.demoOrder || 99);
  }, [energyToEdit]);

  const paramsForEnergiesTable = {
    ...(idFilter && { id: idFilter }),
    ...(statusFilter !== "all" && { status: statusFilter }),
    ...(mostCommonFilter !== "all" && { mostCommon: mostCommonFilter }),
    ...(energyTypeFilter !== "all" && { energyType: energyTypeFilter }),
    ...(unitCostFilter && { unitCost: unitCostFilter }),
    ...(nameEsFilter && { nameEs: nameEsFilter }),
    ...(nameEnFilter && { nameEn: nameEnFilter }),
    ...(unitEsFilter && { unitsEs: unitEsFilter }),
    ...(unitEnFilter && { unitsEn: unitEnFilter }),
    ...(defaultValueFilter && { defaultValue: defaultValueFilter }),
    ...(minValueFilter && { minValue: minValueFilter }),
    ...(maxValueFilter && { maxValue: maxValueFilter }),
    ...(emissionFactorFilter && { emissionFactor: emissionFactorFilter }),
    ...(emissionScopeEsFilter && { emissionScopeEs: emissionScopeEsFilter }),
    ...(emissionScopeEnFilter && { emissionScopeEn: emissionScopeEnFilter }),
    ...(demoOrderFilter && { demoOrder: demoOrderFilter }),
    ...{ pageSize },
    ...{ pageNumber: currentTablePage },
    ...{ sortType },
    ...{ sortBy },
  };

  useEffect(() => {
    if (currentTablePage >= totalEnergyPages) {
      setCurrentTablePage(0);
    }

    if (filteredEnergiesTimeoutId) clearTimeout(filteredEnergiesTimeoutId);
    setFilteredEnergiesTimeoutId(setTimeout(handleFilterEnergies, 1000));
  }, [
    idFilter,
    statusFilter,
    mostCommonFilter,
    energyTypeFilter,
    unitCostFilter,
    nameEsFilter,
    nameEnFilter,
    unitEsFilter,
    unitEnFilter,
    defaultValueFilter,
    minValueFilter,
    maxValueFilter,
    emissionFactorFilter,
    emissionScopeEsFilter,
    emissionScopeEnFilter,
    demoOrderFilter,
    pageSize,
    currentTablePage,
    sortBy,
    sortType,
    totalEnergyPages,
  ]);

  function handleFilterEnergies(data: Number): void {
    dispatch(getFilteredEnergySources(paramsForEnergiesTable));
  }

  const handleOnDeleteConfirm = () => {
    if (selectedEnergyId) {
      dispatch(deleteEnergy(selectedEnergyId, paramsForEnergiesTable));
    }
  };

  const clearForm = () => {
    setSelectedEnergyId(undefined);
    setSpanishName("");
    setEnglishName("");
    setSpanishUnit("");
    setEnglishUnit("");
    setSpanishScope("");
    setEnglishScope("");
    setEnergyType("");
    setMostCommon(false);
    setCost(0);
    setEmissionFactor(0);
    setEnergyContent(0);
    setDefaultValue(0);
    setMinValue(0);
    setMaxValue(0);
    setDemoOrder(99);
  };

  const handleOnClose = () => {
    setSelectedEnergyId(undefined);
    setShowModal(false);
    clearForm();
  };

  const handleOnSave = () => {
    const energyPayload = {
      defaultValue,
      emissionFactor,
      emissionScopeEn: englishScope,
      emissionScopeEs: spanishScope,
      energyContent,
      energyType,
      maxValue,
      minValue,
      mostCommon: mostCommon,
      nameEn: englishName,
      nameEs: spanishName,
      unitCost: cost,
      unitsEn: englishUnit,
      unitsEs: spanishUnit,
      demoOrder: demoOrder,
    };

    if (selectedEnergyId) {
      dispatch(
        editEnergy(selectedEnergyId, energyPayload, paramsForEnergiesTable)
      );
      clearForm();
    } else {
      dispatch(createEnergy(energyPayload, paramsForEnergiesTable));
      clearForm();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMostCommon(event.target.checked);
  };

  const getCreateUserModal = () => {
    return (
      <PungoModal
        open={showModal}
        classNames={styles.modalParentForCreate}
        title={
          selectedEnergyId
            ? `${t("energy.editTitle")}`
            : `${t("energy.createTitle")}`
        }
        handleClose={handleOnClose}
        primaryAction={{
          label: selectedEnergyId
            ? t("admin.editLabel")
            : t("admin.createLabel"),
          onClick: handleOnSave,
          disabled:
            !spanishName ||
            !englishName ||
            !spanishUnit ||
            !englishUnit ||
            !spanishScope ||
            !englishScope ||
            !energyType,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnClose,
        }}
      >
        <div className={styles.modal}>
          <PungoInput
            name={`${t("energy.spanishNameLabel")}`}
            value={spanishName}
            onChange={setSpanishName}
          />
          <PungoInput
            name={`${t("energy.englishNameLabel")}`}
            value={englishName}
            onChange={setEnglishName}
          />
          <PungoInput
            name={`${t("energy.spanishUnitLabel")}`}
            value={spanishUnit}
            onChange={setSpanishUnit}
          />
          <PungoInput
            name={`${t("energy.englishUnitLabel")}`}
            value={englishUnit}
            onChange={setEnglishUnit}
          />
          <PungoInput
            name={`${t("energy.spanishScopeLabel")}`}
            value={spanishScope}
            onChange={setSpanishScope}
          />
          <PungoInput
            name={`${t("energy.englishScopeLabel")}`}
            value={englishScope}
            onChange={setEnglishScope}
          />
          <PungoInput
            name={`${t("energy.energyTypeLabel")}`}
            value={energyType}
            onChange={setEnergyType}
            type="select"
            options={filterTypeOptions}
          />
          <PungoInput
            name={`${t("energy.costLabel")}`}
            value={cost}
            onChange={setCost}
            type="number"
          />
          <PungoInput
            name={`${t("energy.emissionFactorLabel")}`}
            value={emissionFactor}
            onChange={setEmissionFactor}
            type="number"
          />
          <PungoInput
            name={`${t("energy.energyContentLabel")}`}
            value={energyContent}
            onChange={setEnergyContent}
            type="number"
          />
          <PungoInput
            name={`${t("energy.defaultValueLabel")}`}
            value={defaultValue}
            onChange={setDefaultValue}
            type="number"
          />
          <PungoInput
            name={`${t("energy.minValueLabel")}`}
            value={minValue}
            onChange={setMinValue}
            type="number"
          />
          <PungoInput
            name={`${t("energy.maxValueLabel")}`}
            value={maxValue}
            onChange={setMaxValue}
            type="number"
          />
          <div className={styles.centeredItem}>
            <FormControlLabel
              control={<Switch checked={mostCommon} onChange={handleChange} />}
              label={t("energy.mostCommonLabel")}
              labelPlacement="start"
            />
          </div>
          <PungoInput
            name={`${t("energy.demoOrderLabel")}`}
            value={demoOrder}
            onChange={setDemoOrder}
            type="number"
          />
        </div>
      </PungoModal>
    );
  };

  const resetFilters = () => {
    setIdFilter(initialIdFilter);
    setStatusFilter(initialStatusFilter);
    setMostCommonFilter(initialMostCommonFilter);
    setEnergyTyperFilter(initialEnergyTypeFilter);
    setUnitCostFilter(initialUnitCostFilter);
    setNameEsFilter(initialNameEsFilter);
    setNameEnFilter(initialNameEnFilter);
    setUnitEsFilter(initialUnitEsFilter);
    setUnitEnFilter(initialUnitEnFilter);
  };

  const showResetFilters =
    idFilter !== initialIdFilter ||
    statusFilter !== initialStatusFilter ||
    mostCommonFilter !== initialMostCommonFilter ||
    energyTypeFilter !== initialEnergyTypeFilter ||
    unitCostFilter !== initialUnitCostFilter ||
    nameEsFilter !== initialNameEsFilter ||
    nameEnFilter !== initialNameEnFilter ||
    unitEsFilter !== initialUnitEsFilter ||
    unitEnFilter !== initialUnitEnFilter;

  return (
    <div>
      <AdminTable
        columns={columnsForEnergiesTable}
        data={energiesData || []}
        handleOnEdit={setShowModal}
        handleOnDelete={handleOnDeleteConfirm}
        handleOnSelection={setSelectedEnergyId}
        handleOnResetFilters={resetFilters}
        shouldShowResetFilters={showResetFilters}
        actions={["add", "delete", "edit"]}
        sorting={{
          sortedBy: sortBy,
          onSortedBy: setSortBy,
          sortedType: sortType,
          onSortedType: setSortType,
        }}
        pagination={{
          totalPages: totalEnergyPages,
          currentPage: currentTablePage,
          onCurrentPage: setCurrentTablePage,
          pageSize: pageSize,
          onPageSize: setPageSize,
        }}
      />
      {getCreateUserModal()}
    </div>
  );
};

export default AdminEnergiesTable;

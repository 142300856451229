import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

import {
  IEnergyIntensity,
  IEmissionIntensity,
  selectProPlanResults,
} from "store/slices/plan";

import PungoDemoResultsCard from "pungo-ui/PungoDemoResultsCard";
import PungoDemoAccordion from "pungo-ui/PungoDemoAccordion";
import { convertDateToUTC } from "../utils";
import SelectableTabs from "../../common/SelectableTabs";

import colors from "styles/export.module.scss";
import styles from "./index.module.scss";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const IndicatorSection: React.FC = () => {
  const { t } = useTranslation();
  const proPlanResults = useSelector(selectProPlanResults);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [emissionIntensity, setEmissionIntensity] = useState<
    IEmissionIntensity[]
  >(proPlanResults?.emissionIntensity);
  const graphUnit = proPlanResults.production[0].unit || "";
  const [energyIntensity, setEnergyIntensity] = useState<IEnergyIntensity[]>(
    proPlanResults?.energyIntensity
  );

  const isEmissionTable = selectedTab === 0;

  const availableDates = {
    startDate:
      convertDateToUTC(new Date(proPlanResults?.emissionIntensity[0].date)) ||
      new Date(),
    endDate:
      convertDateToUTC(
        new Date(
          proPlanResults?.emissionIntensity[
            proPlanResults?.emissionIntensity.length - 1
          ].date
        )
      ) || new Date(),
    key: "selection",
  };

  const emissionSummaryData = {
    labels: isEmissionTable
      ? emissionIntensity?.map(({ date }) => date)
      : energyIntensity?.map(({ date }) => date),
    datasets: [
      {
        data: isEmissionTable
          ? emissionIntensity?.map(({ emissionIntensity }) => emissionIntensity)
          : energyIntensity?.map(({ energyIntensity }) => energyIntensity),
        borderWidth: 1,
        borderColor: colors.blue3,
        tension: 0.4,
        pointStyle: "circle",
        pointBackgroundColor: colors.red1,
        pointBorderColor: colors.red1,
        pointRadius: 4,
      },
    ],
  };

  const lineChartConfig = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: `${t("proPlan.axisLabels.date")}`,
        },
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: isEmissionTable
            ? `kg CO2-eq / ${graphUnit}`
            : `MJ / ${graphUnit}`,
        },
        grid: {
          lineWidth: 0,
        },
      },
    },
  };

  const [selectedDateRange, setSelectedDateRange] = useState<any | null>([
    availableDates,
  ]);

  useEffect(() => {
    setEmissionIntensity(
      proPlanResults?.emissionIntensity?.filter(
        ({ date }) =>
          convertDateToUTC(new Date(date)) >= selectedDateRange[0].startDate &&
          convertDateToUTC(new Date(date)) <= selectedDateRange[0].endDate
      )
    );
    setEnergyIntensity(
      proPlanResults?.energyIntensity?.filter(
        ({ date }) =>
          convertDateToUTC(new Date(date)) >= selectedDateRange[0].startDate &&
          convertDateToUTC(new Date(date)) <= selectedDateRange[0].endDate
      )
    );
  }, [selectedDateRange, proPlanResults]);

  const getTotalEnergyResults = () => (
    <div className={styles.mainContainer}>
      <SelectableTabs
        labels={[
          t("proPlan.tabs.emissionsIntensity"),
          t("proPlan.tabs.energyIntensity"),
        ]}
        onSelect={setSelectedTab}
        selectedIndex={selectedTab}
      />
      <div className={styles.innerContainer}>
        <div className={styles.upperContainer}>
          <Line data={emissionSummaryData} options={lineChartConfig} />
        </div>
        <div className={styles.middleSection}>
          <div className={styles.result}>
            <PungoDemoResultsCard
              description={
                isEmissionTable
                  ? t("proPlan.resultLabel.emissionsIntensity")
                  : t("proPlan.resultLabel.energyIntensity")
              }
              result={
                isEmissionTable
                  ? `${(
                      emissionIntensity.reduce(
                        (acc, currentValue) =>
                          acc + currentValue.emissionIntensity,
                        0
                      ) / emissionIntensity.length
                    ).toFixed(1)} kg CO2-eq`
                  : `${(
                      energyIntensity.reduce(
                        (acc, currentValue) =>
                          acc + currentValue.energyIntensity,
                        0
                      ) / energyIntensity.length
                    ).toFixed(1)} MJ / ${graphUnit}`
              }
              color={colors.red1}
            />
          </div>
          <div className={styles.datePicker}>
            <span className={styles.pickerInstructions}>
              {t("proPlan.selectRange")}
            </span>
            <DateRangePicker
              onChange={(item) => setSelectedDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              editableDateInputs={true}
              showMonthAndYearPickers={false}
              staticRanges={[]}
              inputRanges={[]}
              ranges={selectedDateRange}
              rangeColors={[colors.green4]}
              shownDate={availableDates.startDate}
              minDate={availableDates.startDate}
              maxDate={availableDates.endDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <PungoDemoAccordion
      title={t("proPlan.labels.indicators")}
      content={getTotalEnergyResults()}
      isPro
    />
  );
};

export default IndicatorSection;

import React, { SyntheticEvent, useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";

import AdminUsersTable from "./tables/AdminUsersTable";
import AdminEnergiesTable from "./tables/AdminEnergiesTable";
import AdminQuestionsTable from "./tables/AdminQuestionsTable";
import AdminUsageTable from "./tables/AdminUsageTable";

import { useTranslation } from "react-i18next";

import useMediaQuery from "@mui/material/useMediaQuery";
import breakpoints from "styles/export.module.scss";
import json2mq from "json2mq";
import { SupervisorAccount, Bolt, Poll, Summarize } from "@mui/icons-material";

import styles from "./index.module.scss";

const AdminPage: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const desktopView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.sm,
    })
  );

  const [selectedTable, setSelectedTable] = useState(0);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setSelectedTable(newValue);
  };

  const getContent = () => {
    switch (selectedTable) {
      case 0:
        return <AdminUsersTable />;
      case 1:
        return <AdminEnergiesTable />;
      case 2:
        return <AdminQuestionsTable />;
      case 3:
        return <AdminUsageTable />;
      default:
        return <AdminUsersTable />;
    }
  };

  return (
    <div className={styles.container}>
      <Tabs
        value={selectedTable}
        onChange={handleChange}
        centered
        className={styles.tabsContainer}
      >
        <Tab
          icon={!desktopView ? <SupervisorAccount /> : undefined}
          label={desktopView ? t("admin.usersLabel") : null}
          className={styles.tab}
        />
        <Tab
          icon={!desktopView ? <Bolt /> : undefined}
          label={desktopView ? t("admin.energiesLabel") : null}
          className={styles.tab}
        />
        <Tab
          icon={!desktopView ? <Summarize /> : undefined}
          label={desktopView ? t("admin.surveyLabel") : null}
          className={styles.tab}
        />
        <Tab
          icon={!desktopView ? <Poll /> : undefined}
          label={desktopView ? t("admin.usageModal") : null}
          className={styles.tab}
        />
      </Tabs>
      {getContent()}
    </div>
  );
};

export default AdminPage;

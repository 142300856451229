import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { useAppDispatch } from "app/hooks";

import { selectSurveyStatus, selectUserRole } from "store/slices/auth";
import AnimationSection from "./sections/AnimationSection";
import DataInputSection from "./sections/DataInputSection";
import DataInputProSection from "./sections/DataInputProSection";
import FAQSection from "components/AucaPage/sections/FAQSection";
import PlanDescription from "./sections/PlanDescription";
import InstructionsSection from "./sections/InstructionsSection";
import ResultsSection from "./sections/ResultsSection";
import ResultsProSection from "./sections/ResultsProSection";
import PlanTableSection from "components/AucaPage/sections/PlanTableSection";
import ContactUsSection from "components/AucaPage/sections/ContactUsSection";
import PungoButton from "pungo-ui/PungoButton";
import PlanSelectorSection from "./sections/DemoPlanSelectorSection";
import {
  getCalculateEnergySources,
  getMockedDataForProPlan,
} from "store/actions/planActions";
import {
  selectFetchedProResultsStatus,
  selectSelectedPlan,
} from "store/slices/plan";

import styles from "./index.module.scss";

const DemoPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishLanguageSelected = i18n.language === "es";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSurveyCompleted = useSelector(selectSurveyStatus);
  const userRoles = useSelector(selectUserRole);
  const isFreeUser = userRoles?.includes("free_plan");
  const selectedPlan = useSelector(selectSelectedPlan);
  const areProResultsFetched = useSelector(selectFetchedProResultsStatus);

  useEffect(() => {
    if (!isSurveyCompleted) {
      navigate("/survey");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurveyCompleted]);

  useEffect(() => {
    if (!isSurveyCompleted) {
      navigate("/survey");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurveyCompleted]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCalculateEnergySources());

    if (isFreeUser) {
      dispatch(getMockedDataForProPlan());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.container}>
      <AnimationSection />

      <PlanDescription />

      <PlanSelectorSection />

      <InstructionsSection />

      {selectedPlan === "free" ? (
        <DataInputSection />
      ) : areProResultsFetched ? (
        <DataInputProSection />
      ) : undefined}

      {selectedPlan === "free" ? (
        <ResultsSection />
      ) : areProResultsFetched ? (
        <ResultsProSection />
      ) : undefined}
      <PlanTableSection />
      <FAQSection />
      <ContactUsSection />

      <div className={styles.languageSection}>
        <PungoButton
          label={`${t("language.englishLabel")}`}
          type="text"
          onClick={() => changeLanguage("en")}
          color="secondary"
          disabled={!isSpanishLanguageSelected}
        />
        <PungoButton
          label={`${t("language.spanishLabel")}`}
          type="text"
          onClick={() => changeLanguage("es")}
          color="secondary"
          disabled={isSpanishLanguageSelected}
        />
      </div>
      <div className={styles.copyright}>Copyright © 2023 Pungo</div>
    </section>
  );
};

export default DemoPage;

// ****************************************************************************************
//  Auca Component
//  Presnetes plan by transaction pricing
//
//  Based on pungo components PungoPlanByTransactionGroup and PungoPlanByTransactionCard
// ****************************************************************************************

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PungoPlanByTransactionGroup from "pungo-ui/PungoPlanByTransactionGroup";
import classNames from "classnames";
import styles from "./index.module.scss";

interface PungoPlanByTransactionCardProps {
  id: string;
  name: string;
  description: string;
  cost: string;
  commentToCost: string;
  frequency: string;
  link: string;
  benefits: string[];
}

const PlanByTransactionSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();
  const handleGoToDemo = (e: any) => {

    if (e.id !== "Plan4") {
      navigate("signup");
    } else {
      window.location.href = "#contactUs";
    }
  };

  const dataMonth: PungoPlanByTransactionCardProps[] = [
    {
      id: "Plan1",
      name: `${t("planByTransaction.payByMonth.Plan1.name")}`,
      description: `${t("planByTransaction.payByMonth.Plan1.description")}`,
      cost: "0",
      commentToCost: `${t("planByTransaction.payByMonth.Plan1.commentToCost")}`,
      frequency: "",
      link: `${t("planByTransaction.payByMonth.Plan1.link")}`,
      benefits: [`${t("planByTransaction.payByMonth.Plan1.benefits.b0")}`],
    },
    {
      id: "Plan2",
      name: `${t("planByTransaction.payByMonth.Plan2.name")}`,
      description: `${t("planByTransaction.payByMonth.Plan2.description")}`,
      cost: "49",
      commentToCost: `${t("planByTransaction.payByMonth.Plan2.commentToCost")}`,
      frequency: `${t("planByTransaction.payByMonth.Plan2.frequency")}`,
      link: `${t("planByTransaction.payByMonth.Plan2.link")}`,
      benefits: [`${t("planByTransaction.payByMonth.Plan2.benefits.b0")}`, `${t("planByTransaction.payByMonth.Plan2.benefits.b1")}`, `${t("planByTransaction.payByMonth.Plan2.benefits.b2")}`],
    },
    {
      id: "Plan3",
      name: `${t("planByTransaction.payByMonth.Plan3.name")}`,
      description: `${t("planByTransaction.payByMonth.Plan3.description")}`,
      cost: "299",
      commentToCost: `${t("planByTransaction.payByMonth.Plan3.commentToCost")}`,
      frequency: `${t("planByTransaction.payByMonth.Plan3.frequency")}`,
      link: `${t("planByTransaction.payByMonth.Plan3.link")}`,
      benefits: [`${t("planByTransaction.payByMonth.Plan3.benefits.b0")}`, `${t("planByTransaction.payByMonth.Plan3.benefits.b1")}`, `${t("planByTransaction.payByMonth.Plan3.benefits.b2")}`],
    },
    {
      id: "Plan4",
      name: `${t("planByTransaction.payByMonth.Plan4.name")}`,
      description: `${t("planByTransaction.payByMonth.Plan4.description")}`,
      cost: "",
      commentToCost: `${t("planByTransaction.payByMonth.Plan4.commentToCost")}`,
      frequency: "",
      link: `${t("planByTransaction.payByMonth.Plan4.link")}`,
      benefits: [`${t("planByTransaction.payByMonth.Plan4.benefits.b0")}`, `${t("planByTransaction.payByMonth.Plan4.benefits.b1")}`],
    },
  ];

  const dataYear: PungoPlanByTransactionCardProps[] = [
    {
      id: "Plan1",
      name: `${t("planByTransaction.payByYear.Plan1.name")}`,
      description: `${t("planByTransaction.payByYear.Plan1.description")}`,
      cost: "0",
      commentToCost: " ",
      frequency: "",
      link: `${t("planByTransaction.payByYear.Plan1.link")}`,
      benefits: [`${t("planByTransaction.payByYear.Plan1.benefits.b0")}`],
    },
    {
      id: "Plan2",
      name: `${t("planByTransaction.payByYear.Plan2.name")}`,
      description: `${t("planByTransaction.payByYear.Plan2.description")}`,
      cost: "44",
      commentToCost: `${t("planByTransaction.payByYear.Plan2.commentToCost")}`,
      frequency: `${t("planByTransaction.payByYear.Plan2.frequency")}`,
      link: `${t("planByTransaction.payByYear.Plan2.link")}`,
      benefits: [`${t("planByTransaction.payByYear.Plan2.benefits.b0")}`, `${t("planByTransaction.payByYear.Plan2.benefits.b1")}`, `${t("planByTransaction.payByYear.Plan2.benefits.b2")}`],
    },
    {
      id: "Plan3",
      name: `${t("planByTransaction.payByYear.Plan3.name")}`,
      description: `${t("planByTransaction.payByYear.Plan3.description")}`,
      cost: "269",
      commentToCost: `${t("planByTransaction.payByYear.Plan3.commentToCost")}`,
      frequency: `${t("planByTransaction.payByYear.Plan3.frequency")}`,
      link: `${t("planByTransaction.payByYear.Plan3.link")}`,
      benefits: [`${t("planByTransaction.payByYear.Plan3.benefits.b0")}`, `${t("planByTransaction.payByYear.Plan3.benefits.b1")}`, `${t("planByTransaction.payByYear.Plan3.benefits.b2")}`],
    },
    {
      id: "Plan4",
      name: `${t("planByTransaction.payByYear.Plan4.name")}`,
      description: `${t("planByTransaction.payByYear.Plan4.description")}`,
      cost: "",
      commentToCost: " ",
      frequency: "",
      link: `${t("planByTransaction.payByYear.Plan4.link")}`,
      benefits: [`${t("planByTransaction.payByYear.Plan4.benefits.b0")}`, `${t("planByTransaction.payByYear.Plan4.benefits.b1")}`],
    },
  ];

  const [plan, setPlan] = useState("monthly");
  const [period, setPeriod] = useState("Mensual");
  const [data, setData] = useState(dataMonth);

  const handlePlanChange = (newPlan: string) => {
    setPlan(newPlan);
    if (newPlan === "annual") {
      setPeriod(`${t("planByTransaction.annual")}`);
      setData(dataYear);
    } else {
      setPeriod(`${t("planByTransaction.monthly")}`);
      setData(dataMonth);
    }
  };

  useEffect(() => {
    if (plan === "annual") {
      setData(dataMonth);
      setData(dataYear);
    } else {
      setData(dataYear);
      setData(dataMonth);
    }
    //eslint-disable-next-line
  }, [language]);

  return (
    <>
      {/* =======================================
            billing period selector 
            ========================================*/}
      <PungoSectionCard title={`${t("planByTransaction.title")}`} description={`${t("planByTransaction.description")}`} id="plans" distribution = "block">
        <div className={styles.planSwitcher}>
          <button
            className={classNames(styles.planButton, {
              [styles.selected]: plan === "monthly",
            })}
            onClick={() => handlePlanChange("monthly")}
          >
            {`${t("planByTransaction.monthly")}`}
          </button>
          

          <button
            className={classNames(styles.planButton, {
              [styles.selected]: plan === "annual",
            })}
            onClick={() => handlePlanChange("annual")}
          >
            {`${t("planByTransaction.annual")}`}
          </button>
        </div>

        {/* ====================================================
            Call to price by transcation component with data
            =================================================*/}
        <div className={styles.container}>
          <PungoPlanByTransactionGroup data={data} period={period} handleClick={handleGoToDemo} />
        </div>
      </PungoSectionCard>
    </>
  );
};

export default PlanByTransactionSection;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AccountCircle } from "@mui/icons-material";
import { changeLanguage } from "i18next";
import { useNavigate } from "react-router";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useAppDispatch } from "app/hooks";

import star from "../../assets/PungoIcons/Star.svg";
import PungoInput from "pungo-ui/PungoInput";
import PungoButton from "pungo-ui/PungoButton";
import { updateProfile } from "store/actions/profileActions";
import { selectPlan, selectProfile } from "store/slices/profile";

import styles from "./index.module.scss";

const AdminProfilePage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const planInfo = useSelector(selectPlan);
  const profileInfo = useSelector(selectProfile);

  const [initialName, setInitialName] = useState("");
  const [initialCompany, setInitialCompany] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setInitialName(profileInfo?.name);
    setInitialCompany(profileInfo?.company);
    setName(profileInfo?.name);
    setCompany(profileInfo?.company);
  }, [profileInfo]);

  const getAccountInfo = () => (
    <div className={styles.planContainer}>
      <div className={styles.planSection}>
        <div className={styles.upperSection}>
          <img src={star} alt="star" className={styles.star} />
          <span className={styles.plan}>Plan PRO</span>
        </div>
        <div className={styles.planStatus}>
          <span className={styles.dot} />
          {planInfo.proPlanActive
            ? t("manageProfile.planStatusActive")
            : t("manageProfile.planStatusInactive")}
        </div>
      </div>
      <div className={styles.paymentSection}>
        <span className={styles.plan}>{t("manageProfile.paymentMethod")}</span>
        {planInfo.paymentMethodType === "CREDIT_CARD" ? (
          <span>{t("manageProfile.creditCard")}</span>
        ) : (
          <span>{t("manageProfile.manual")}</span>
        )}
        {planInfo.endDate && (
          <span>{`${t("manageProfile.usageDate")} ${planInfo.endDate}`}</span>
        )}
        {planInfo.paymentMethodBrand && (
          <span>{planInfo.paymentMethodBrand}</span>
        )}
        {planInfo.remainingUsages && (
          <span>{`${t("manageProfile.remainingUsages")} ${
            planInfo.remainingUsages
          }`}</span>
        )}
        {planInfo.paymentMethodLastDigits && (
          <span>{`**** **** **** ${planInfo.paymentMethodLastDigits}`}</span>
        )}
        {planInfo.paymentMethodExpiration && (
          <span>{`${t("manageProfile.expires")} ${
            planInfo.paymentMethodExpiration
          }`}</span>
        )}
      </div>
    </div>
  );

  const updateProfileInfo = () => {
    dispatch(updateProfile({ name: initialName, companyName: initialCompany }));
    setName(initialName);
    setCompany(initialCompany);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.element}>
        <div className={styles.title}>{t("manageProfile.profileLabel")}</div>
        <div className={styles.profileContent}>
          <div className={styles.photo}>
            <AccountCircle fontSize="inherit" className={styles.icon} />
            {profileInfo.email}
            <PungoButton
              label={`${t("changePasswordLabel")}`}
              type="text"
              onClick={() => navigate("/password")}
              color="secondary"
            />
            <div className={styles.changeLanguage}>
              <span className={styles.label}>{`${t(
                "manageProfile.changeLanguage"
              )}`}</span>
              <FormControl size="small">
                <Select
                  value={i18n.language}
                  onChange={(e) => changeLanguage(e.target.value)}
                >
                  <MenuItem value="es">{`${t(
                    "language.spanishLabel"
                  )}`}</MenuItem>
                  <MenuItem value="en">{`${t(
                    "language.englishLabel"
                  )}`}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.inputSection}>
            <div className={styles.input}>
              <PungoInput
                name={`${t("manageProfile.nameLabel")}`}
                value={initialName}
                onChange={setInitialName}
              />
            </div>
            <div className={styles.input}>
              <PungoInput
                name={`${t("manageProfile.companyLabel")}`}
                value={initialCompany}
                onChange={setInitialCompany}
              />
            </div>
            <div className={styles.saveChanges}>
              <div className={styles.button}>
                <PungoButton
                  label={`${t("manageProfile.saveChangesLabel")}`}
                  onClick={updateProfileInfo}
                  color="primary"
                  disabled={
                    !(name !== initialName || company !== initialCompany)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.element}>
        <div className={styles.title}>{t("manageProfile.accountLabel")}</div>
        <div className={styles.content}>{getAccountInfo()}</div>
      </div>
    </div>
  );
};

export default AdminProfilePage;

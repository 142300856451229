import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUserRole } from "store/slices/auth";

import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";



import styles from "./index.module.scss";

const PlanDescription: React.FC = () => {
  const { t } = useTranslation();

  const userRoles = useSelector(selectUserRole);


  // const isFreeUser = userRoles.includes("free_plan");
  const str = JSON.stringify(userRoles);
  const proPlan = str.search("pro");

  const getPlanDescription = () => (
    <>
      <div className={styles.text}>
      
          <Trans>
            {t("DemoPage.PlanDescription.description1")}
          </Trans>

      

            <span className={styles.accents}>
              {proPlan > 0
                ? <Trans> {t("DemoPage.PlanButtons.proPlan")} </Trans> 
                : <Trans> {t("DemoPage.PlanButtons.freePlan")} </Trans>
              }
            </span>

            <Trans>
              {t("DemoPage.PlanDescription.descriptionFree")}
            </Trans>
      
      </div>
    </>
  );



  return (
    <PungoSectionCard
      title={t("")}
      id="planDescription"
    >

      <div className={styles.container}>
        <div className={styles.accordionContainer}>

          {getPlanDescription()}

        </div>

      </div>
    </PungoSectionCard>
  );
};

export default PlanDescription;

import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ProBadge from "../../components/common/ProBadge";

import styles from "./index.module.scss";

interface PungoDemoAccordionProps {
  title: string;
  content?: React.ReactNode;
  isPro?: boolean;
}

const PungoDemoAccordion: React.FC<PungoDemoAccordionProps> = (props) => {
  const { title, content, isPro = false } = props;
  const [expanded, setExpanded] = React.useState<string | boolean>("panel1");

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      expanded={!!expanded}
      onChange={handleChange("panel1")}
      elevation={0}
      className={styles.container}
      sx={{
        "&.MuiAccordion-root": {
          borderRadius: "10px",
          boxShadow: "none",
        },
      }}
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <div className={styles.titleContainer}>
          <div className={styles.accordionTitle}>{title}</div>
          {isPro && (
            <div className={styles.badge}>
              <ProBadge />
            </div>
          )}

          <div className={styles.icon}>
            {expanded ? (
              <ExpandLessIcon fontSize="large" />
            ) : (
              <ExpandMoreIcon fontSize="large" />
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};

export default PungoDemoAccordion;

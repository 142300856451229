import React, { useEffect, useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";
import { useTranslation } from "react-i18next";

import {
  clearUsageById,
  clearUsageDataByUser,
  selectAllRoles,
  selectAllUsers,
  selectTotalUsersPages,
  selectUsageById,
  selectUsageByUserId,
  selectUsageTotalUsersPages,
} from "store/slices/user";
import AdminTable, { ITableColumn } from "../common/AdminTable";
import {
  IPaymentTypes,
  IUsageTypes,
  createUser,
  deleteUsers,
  editUser,
  fetchUsageById,
  fetchUsageByUserId,
  getFilteredUsers,
  getRoles,
} from "store/actions/userActions";
import PungoModal from "pungo-ui/PungoModal";
import PungoInput from "pungo-ui/PungoInput";
import { isEmail } from "components/utils/validations";
import { FormControlLabel, Switch } from "@mui/material";

import styles from "./index.module.scss";

export interface IFilterOption {
  label: string;
  type: string;
  defaultFilterValue: string;
}

const AdminUsersTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const usersData = useSelector(selectAllUsers);
  const rolesList = useSelector(selectAllRoles);
  const usageByUserData = useSelector(selectUsageByUserId);
  const detailedData = useSelector(selectUsageById);
  const totalUserPages = useSelector(selectTotalUsersPages);
  const totalUsageUserPages = useSelector(selectUsageTotalUsersPages);

  const initialIdFilter = "";
  const initialStatusFilter = "ACTIVE";
  const initialRoleFilter = "all";
  const initialNameFilter = "";
  const initialMailFilter = "";
  const initialCompanyFilter = "";

  const initialIdFilterByUserId = "";
  const initialDateFilterByUserId = "";
  const initialUserIdFilterByUserId = "";
  const initialMailFilterByUserId = "";
  const initialCallTypeFilterByUserId = "all";

  const [idFilter, setIdFilter] = useState(initialIdFilter);
  const [statusFilter, setStatusFilter] = useState(initialStatusFilter);
  const [roleFilter, setRoleFilter] = useState(initialRoleFilter);
  const [nameFilter, setNameFilter] = useState(initialNameFilter);
  const [mailFilter, setMailFilter] = useState(initialMailFilter);
  const [companyFilter, setCompanyFilter] = useState(initialCompanyFilter);

  const [usageDateFilter, setUsageDateFilter] = useState(
    initialDateFilterByUserId
  );
  const [usageIdFilter, setUsageIdFilter] = useState(initialIdFilterByUserId);
  const [usageUserIdFilter, setUsageUserIdFilter] = useState(
    initialUserIdFilterByUserId
  );
  const [usageMailFilter, setUsageMailFilter] = useState(
    initialMailFilterByUserId
  );
  const [usageCallTypeFilter, setUsageCallTypeFilter] = useState(
    initialCallTypeFilterByUserId
  );

  const [selectedUserId, setSelectedUserId] = useState<number | undefined>();
  const [selectedUsageId, setSelectedUsageId] = useState<number | undefined>();
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [usageType, setUsageType] = useState<IUsageTypes>("");
  const [paymentType, setPaymentType] = useState<IPaymentTypes>("");
  const [remainingUsages, setRemainingUsages] = useState(0);
  const [endDate, setEndDate] = useState("");
  const [emailVerificationCode, setEmailVerificationCode] = useState("");
  const [passwordChangeCode, setPasswordChangeCode] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [isSurveyCompleted, setIsSurveyCompleted] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [usagePageSize, setUsagePageSize] = useState(10);
  const [currentUsageTablePage, setCurrentUsageTablePage] = useState(0);
  const [sortBy, setSortBy] = useState("id");
  const [sortType, setSortType] = useState("asc");
  const [usageSortBy, setUsageSortBy] = useState("id");
  const [usageSortType, setUsageSortType] = useState("asc");

  const [showModal, setShowModal] = useState(false);
  const [showUsageModal, setShowUsageModal] = useState(false);
  const [showDetailedUsageModal, setShowDetailedUsageModal] = useState(false);
  const [filteredUsersCallTimeoutId, setFilteredUsersTimeoutId] = useState(0);
  const [filteredUsersByIdCallTimeoutId, setFilteredUsersByIdTimeoutId] =
    useState(0);

  const isProUser = role === "pro_plan";
  const isNumberUsage = usageType === "NUMBER_OF_USAGES";
  const isEndDateUsage = usageType === "END_TIME";

  const filterStatusOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.active"), value: "ACTIVE" },
    { label: t("admin.filtering.removed"), value: "REMOVED" },
  ];

  const filterCallTypeOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.proPlan"), value: "PRO_PLAN" },
    { label: t("admin.filtering.simplePlan"), value: "SIMPLE_PLAN" },
  ];

  const filterRoleOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.admin"), value: "admin" },
    { label: t("admin.filtering.pro"), value: "pro_plan" },
    { label: t("admin.filtering.free"), value: "free_plan" },
  ];

  const paramsForUserTable = {
    ...(idFilter && { id: idFilter }),
    ...(statusFilter !== "all" && { status: statusFilter }),
    ...(nameFilter && { name: nameFilter }),
    ...(companyFilter && { companyName: companyFilter }),
    ...(mailFilter && { email: mailFilter }),
    ...(roleFilter !== "all" && { role: roleFilter }),
    ...{ pageSize },
    ...{ pageNumber: currentTablePage },
    ...{ sortType },
    ...{ sortBy },
  };

  const paramsForUsageTable = {
    ...(usageDateFilter && { time: usageDateFilter }),
    ...(usageIdFilter && { id: usageIdFilter }),
    ...(usageUserIdFilter && { user_id: usageUserIdFilter }),
    ...(usageMailFilter && { user_email: usageMailFilter }),
    ...(usageCallTypeFilter !== "all" && { dtype: usageCallTypeFilter }),
    ...{ pageSize: usagePageSize },
    ...{ pageNumber: currentUsageTablePage },
    ...{ sortType: usageSortType },
    ...{ sortBy: usageSortBy },
  };

  useEffect(() => {
    if (currentTablePage >= totalUserPages) {
      setCurrentTablePage(0);
    }

    if (filteredUsersCallTimeoutId) clearTimeout(filteredUsersCallTimeoutId);
    setFilteredUsersTimeoutId(setTimeout(handleFilterUsers, 1000));
  }, [
    idFilter,
    statusFilter,
    roleFilter,
    nameFilter,
    companyFilter,
    mailFilter,
    pageSize,
    currentTablePage,
    sortBy,
    sortType,
    totalUserPages,
  ]);

  function handleFilterUsers(data: Number): void {
    dispatch(getFilteredUsers(paramsForUserTable));
  }

  useEffect(() => {
    if (currentUsageTablePage >= totalUsageUserPages) {
      setCurrentUsageTablePage(0);
    }

    if (filteredUsersByIdCallTimeoutId)
      clearTimeout(filteredUsersByIdCallTimeoutId);
    setFilteredUsersByIdTimeoutId(setTimeout(handleFilterUsersById, 1000));
  }, [
    usageDateFilter,
    usageIdFilter,
    usageUserIdFilter,
    usageMailFilter,
    usageCallTypeFilter,
    usagePageSize,
    currentUsageTablePage,
    totalUsageUserPages,
    usageSortType,
    usageSortBy,
  ]);

  function handleFilterUsersById(data: Number): void {
    if (selectedUserId) {
      dispatch(fetchUsageByUserId(selectedUserId, paramsForUsageTable));
    }
  }

  const columnsForUserTable: ITableColumn[] = [
    {
      id: "id",
      field: "id",
      headerName: "ID",
      minWidth: 150,
      sortable: false,
      filter: {
        name: "ID",
        type: "text",
        value: idFilter,
        setter: setIdFilter,
      },
    },
    {
      id: "status",
      field: "status",
      headerName: "Status",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.status"),
        type: "list",
        value: statusFilter,
        setter: setStatusFilter,
        options: filterStatusOptions,
      },
    },
    {
      id: "role",
      field: "role",
      headerName: "Role",
      minWidth: 230,
      sortable: false,
      filter: {
        name: t("admin.filtering.role"),
        type: "list",
        value: roleFilter,
        setter: setRoleFilter,
        options: filterRoleOptions,
      },
    },
    {
      id: "name",
      field: "name",
      headerName: "Name",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.name"),
        type: "text",
        value: nameFilter,
        setter: setNameFilter,
      },
    },
    {
      id: "email",
      field: "email",
      headerName: "Email",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.mail"),
        type: "text",
        value: mailFilter,
        setter: setMailFilter,
      },
    },
    {
      id: "companyName",
      field: "companyName",
      headerName: "Company",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.company"),
        type: "text",
        value: companyFilter,
        setter: setCompanyFilter,
      },
    },
  ];

  const columnsForUsageTable: ITableColumn[] = [
    {
      id: "time",
      field: "time",
      headerName: "Time",
      sortable: false,
      minWidth: 250,
      filter: {
        name: t("admin.filtering.date"),
        type: "text",
        value: usageDateFilter,
        setter: setUsageDateFilter,
      },
    },
    {
      id: "id",
      field: "id",
      headerName: "ID",
      sortable: false,
      minWidth: 150,
      filter: {
        name: "ID",
        type: "text",
        value: usageIdFilter,
        setter: setUsageIdFilter,
      },
    },
    {
      id: "userId",
      field: "userId",
      headerName: "User Id",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.userId"),
        type: "text",
        value: usageUserIdFilter,
        setter: setUsageUserIdFilter,
      },
    },
    {
      id: "userName",
      field: "userName",
      headerName: "User's email",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.mail"),
        type: "text",
        value: usageMailFilter,
        setter: setUsageMailFilter,
      },
    },
    {
      id: "callType",
      field: "callType",
      headerName: "Call type",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.callType"),
        type: "list",
        value: usageCallTypeFilter,
        setter: setUsageCallTypeFilter,
        options: filterCallTypeOptions,
      },
    },
  ];

  const userToEdit = usersData?.find(({ id }) => id === selectedUserId);

  useEffect(() => {
    setName(userToEdit?.name || "");
    setCompany(userToEdit?.companyName || "");
    setEmail(userToEdit?.email || "");
    setRole(userToEdit?.role || "");
    setEmailVerificationCode(userToEdit?.emailVerificationCode || "");
    setPasswordChangeCode(userToEdit?.passwordChangeCode || "");
    setIsEmailVerified(userToEdit?.emailVerified || false);
    setIsSurveyCompleted(userToEdit?.surveyCompleted || false);
    setUsageType(userToEdit?.planUsageType || "");
    setPaymentType(userToEdit?.planPaymentType || "");
    setRemainingUsages(userToEdit?.planRemainingUsages || 0);
    setEndDate(userToEdit?.planEndDate || "");
  }, [userToEdit]);

  userToEdit?.planEndDate ? new Date(userToEdit?.planEndDate) : new Date();

  useEffect(() => {
    if (selectedUserId) {
      dispatch(fetchUsageByUserId(selectedUserId, paramsForUsageTable));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUsageModal]);

  useEffect(() => {
    if (selectedUsageId) {
      dispatch(fetchUsageById(selectedUsageId));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDetailedUsageModal]);

  const handleOnDeleteConfirm = () => {
    if (selectedUserId) {
      dispatch(deleteUsers(selectedUserId, paramsForUserTable));
    }
  };

  useEffect(() => {
    if (email.length) {
      setTimeout(() => {
        if (isEmail(email)) {
          setIsValidEmail(true);
        } else {
          setIsValidEmail(false);
        }
      }, 300);
    }
  }, [email]);

  const clearForm = () => {
    setSelectedUserId(undefined);
    setName("");
    setCompany("");
    setEmail("");
    setRole("");
    setPassword("");
    setEmailVerificationCode("");
    setPasswordChangeCode("");
    setIsEmailVerified(false);
    setIsSurveyCompleted(false);
    setUsageType("");
    setPaymentType("");
    setRemainingUsages(0);
    setEndDate("");
  };

  const handleOnClose = () => {
    setSelectedUserId(undefined);
    setShowModal(false);
    clearForm();
  };

  const handleOnSave = () => {
    const userPayload = {
      username: email,
      name: name,
      email: email,
      ...(password && { password }),
      role,
      companyName: company,
      emailVerified: isEmailVerified,
      surveyCompleted: isSurveyCompleted,
      ...(emailVerificationCode && { emailVerificationCode }),
      ...(passwordChangeCode && { passwordChangeCode }),
      planUsageType: usageType || null,
      planPaymentType: paymentType || null,
      planEndDate: endDate,
      planRemainingUsages: remainingUsages,
    };

    if (selectedUserId) {
      dispatch(editUser(selectedUserId, userPayload, paramsForUserTable));
      clearForm();
    } else {
      dispatch(createUser(userPayload, paramsForUserTable));
      clearForm();
    }
  };

  const handleOnCloseUsageModal = () => {
    dispatch(clearUsageDataByUser());
    setShowUsageModal(false);
    setSelectedUserId(undefined);
  };

  const handleOnCloseDetailedUsageModal = () => {
    dispatch(clearUsageById());
    setShowDetailedUsageModal(false);
    setSelectedUsageId(undefined);
  };

  const handleChangeVerifiedMail = (event: ChangeEvent<HTMLInputElement>) => {
    setIsEmailVerified(event.target.checked);
  };

  const handleChangeSurveyCompleted = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setIsSurveyCompleted(event.target.checked);
  };

  const paymentTypes = [
    { value: "MANUAL", label: "Manual" },
    {
      value: "CREDIT_CARD",
      label: ` 
  ${t("user.creditCard")}`,
    },
  ];

  const usageTypes = [
    {
      value: "END_TIME",
      label: ` 
  ${t("user.endTime")}`,
    },
    {
      value: "NUMBER_OF_USAGES",
      label: ` 
  ${t("user.usageNumber")}`,
    },
  ];

  const isContinueButtonDisabled =
    !name ||
    !email ||
    !role ||
    !company ||
    !isValidEmail ||
    (selectedUserId ? false : !password);

  const getCreateUserModal = () => {
    return (
      <PungoModal
        open={showModal}
        classNames={styles.modalParentForCreate}
        title={
          selectedUserId
            ? `${t("admin.editTitle")}`
            : `${t("admin.createTitle")}`
        }
        handleClose={handleOnClose}
        primaryAction={{
          label: selectedUserId ? t("admin.editLabel") : t("admin.createLabel"),
          onClick: handleOnSave,
          disabled: isContinueButtonDisabled,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnClose,
        }}
      >
        <div className={styles.modal}>
          <PungoInput
            name={`${t("user.nameLabel")}`}
            value={name}
            onChange={setName}
          />
          <PungoInput
            name={`${t("user.companyLabel")}`}
            value={company}
            onChange={setCompany}
          />
          <PungoInput
            name={`${t("user.emailLabel")}`}
            value={email}
            onChange={setEmail}
            disabled={selectedUserId ? true : false}
            helperText={
              isValidEmail ? undefined : `${t("accountPage.invalidEmail")}`
            }
          />
          <PungoInput
            name={`${t("user.passwordLabel")}`}
            value={password}
            onChange={setPassword}
            type="password"
          />
          <PungoInput
            name={`${t("user.emailVerificationCodeLabel")}`}
            value={emailVerificationCode}
            onChange={setEmailVerificationCode}
          />
          <PungoInput
            name={`${t("user.passwordChangeCodeLabel")}`}
            value={passwordChangeCode}
            onChange={setPasswordChangeCode}
          />
          <PungoInput
            name={`${t("user.roleLabel")}`}
            value={role}
            onChange={setRole}
            type="select"
            options={rolesList.map(({ role }) => {
              return { value: role, label: role };
            })}
          />
          {isProUser && (
            <PungoInput
              name={`${t("user.paymentType")}`}
              value={paymentType || ""}
              onChange={setPaymentType}
              type="select"
              options={paymentTypes.map((type) => type)}
            />
          )}
          {isProUser && (
            <PungoInput
              name={`${t("user.usageType")}`}
              value={usageType || ""}
              onChange={setUsageType}
              type="select"
              options={usageTypes.map((type) => type)}
            />
          )}
          {isProUser && isNumberUsage && (
            <PungoInput
              name={`${t("user.remainingUsages")}`}
              value={remainingUsages}
              onChange={setRemainingUsages}
              type="number"
              options={usageTypes.map((type) => type)}
            />
          )}
          {isProUser && isEndDateUsage && (
            <PungoInput
              name={`${t("user.usageEndDate")}`}
              value={endDate}
              type="date"
              onChange={(newValue) => setEndDate(newValue)}
            />
          )}
          <div className={styles.centeredItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={isEmailVerified}
                  onChange={handleChangeVerifiedMail}
                />
              }
              label={t("user.verifiedEmailLabel")}
              labelPlacement="start"
            />
          </div>
          <div className={styles.centeredItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={isSurveyCompleted}
                  onChange={handleChangeSurveyCompleted}
                />
              }
              label={t("user.completedSurveyLabel")}
              labelPlacement="start"
            />
          </div>
        </div>
      </PungoModal>
    );
  };

  const resetFilters = () => {
    setIdFilter(initialIdFilter);
    setStatusFilter(initialStatusFilter);
    setRoleFilter(initialRoleFilter);
    setNameFilter(initialNameFilter);
    setMailFilter(initialMailFilter);
    setCompanyFilter(initialCompanyFilter);
  };

  const resetFiltersByUserId = () => {
    setUsageIdFilter(initialIdFilterByUserId);
    setUsageDateFilter(initialDateFilterByUserId);
    setUsageUserIdFilter(initialUserIdFilterByUserId);
    setUsageMailFilter(initialMailFilterByUserId);
    setUsageCallTypeFilter(initialCallTypeFilterByUserId);
  };

  const showResetFilters =
    idFilter !== initialIdFilter ||
    statusFilter !== initialStatusFilter ||
    roleFilter !== initialRoleFilter ||
    nameFilter !== initialNameFilter ||
    mailFilter !== initialMailFilter ||
    companyFilter !== initialCompanyFilter;

  const showResetFiltersUserById =
    usageDateFilter !== initialDateFilterByUserId ||
    usageIdFilter !== initialIdFilterByUserId ||
    usageUserIdFilter !== initialUserIdFilterByUserId ||
    usageMailFilter !== initialMailFilterByUserId ||
    usageCallTypeFilter !== initialCallTypeFilterByUserId;

  return (
    <div>
      <AdminTable
        columns={columnsForUserTable}
        data={usersData || []}
        handleOnEdit={setShowModal}
        handleOnDelete={handleOnDeleteConfirm}
        handleOnFetchRol={() => dispatch(getRoles())}
        handleOnSelection={setSelectedUserId}
        handleOnUsage={setShowUsageModal}
        actions={["stats", "add", "delete", "edit"]}
        shouldShowResetFilters={showResetFilters}
        handleOnResetFilters={resetFilters}
        pagination={{
          totalPages: totalUserPages,
          currentPage: currentTablePage,
          onCurrentPage: setCurrentTablePage,
          pageSize: pageSize,
          onPageSize: setPageSize,
        }}
        sorting={{
          sortedBy: sortBy,
          onSortedBy: setSortBy,
          sortedType: sortType,
          onSortedType: setSortType,
        }}
      />
      {getCreateUserModal()}
      <PungoModal
        open={showUsageModal}
        title={`${t("admin.usageModal")}`}
        handleClose={handleOnCloseUsageModal}
        classNames={styles.modalParentForUsage}
        primaryAction={{
          label: "Ok",
          onClick: handleOnCloseUsageModal,
        }}
      >
        <AdminTable
          columns={columnsForUsageTable}
          data={usageByUserData || []}
          handleOnUsage={setShowDetailedUsageModal}
          handleOnSelection={setSelectedUsageId}
          handleOnResetFilters={resetFiltersByUserId}
          shouldShowResetFilters={showResetFiltersUserById}
          actions={["stats"]}
          classNames={styles.usageModal}
          sorting={{
            sortedBy: usageSortBy,
            onSortedBy: setUsageSortBy,
            sortedType: usageSortType,
            onSortedType: setUsageSortType,
          }}
          pagination={{
            totalPages: totalUsageUserPages,
            currentPage: currentUsageTablePage,
            onCurrentPage: setCurrentUsageTablePage,
            pageSize: usagePageSize,
            onPageSize: setUsagePageSize,
          }}
        />
      </PungoModal>
      <PungoModal
        open={showDetailedUsageModal}
        title={`${t("admin.detailsLabel")}`}
        handleClose={handleOnCloseDetailedUsageModal}
        classNames={styles.detailsModal}
        primaryAction={{
          label: "Ok",
          onClick: handleOnCloseDetailedUsageModal,
        }}
      >
        <code className={styles.detailsModalChild}>
          <pre>{JSON.stringify(detailedData, null, 4)}</pre>
        </code>
      </PungoModal>
    </div>
  );
};

export default AdminUsersTable;

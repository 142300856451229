import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { displaySnackbarAPIError } from "store/actions/utils";
import { selectUserName, selectUserRole } from "store/slices/auth";
import { isAuthorized } from "utils/auth";
import { useAppDispatch } from "app/hooks";

import Navbar from "../Navbar";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

interface LayoutProps {
  component: React.ReactNode;
  authPermission?: string;
  navbarType?: "home" | "user" | "plan" | "structure";
}

const Layout: React.FC<LayoutProps> = (props) => {
  const { component, navbarType, authPermission } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authUser = useSelector(selectUserName);
  const role = useSelector(selectUserRole);

  useEffect(() => {
    if (!authUser) {
      if (authPermission) {
        const isAuth = isAuthorized(authPermission, role[0]);
        if (isAuth.hidden) {
          navigate("/login");
          dispatch(displaySnackbarAPIError(t("sessionLostLabel")));
        }
      }
    } else {
      if (authPermission) {
        const isAuth = isAuthorized(authPermission, role[0]);
        if (isAuth.hidden) {
          navigate("/unauthorized");
        }
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, role]);

  return (
    <div>
      <div className={styles.navbar}>
        <Navbar type={navbarType} />
      </div>
      <div>{component}</div>
    </div>
  );
};

export const withLayout = (
  wrappedComponent: React.ReactNode,
  authPermission?: string,
  type?: "home" | "user" | "plan" | "structure"
) => (
  <Layout
    component={wrappedComponent}
    navbarType={type}
    authPermission={authPermission}
  />
);

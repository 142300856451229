import axios, { AxiosRequestConfig } from "axios";
import { store } from "../store";
import i18next from "i18next";

export const axiosInstance = axios.create();
export const axiosInstanceAWS = axios.create();

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    const headers: any = {
      "Content-Type": "application/json",
      Accept: "*/*",

      "Accept-Language": i18next.language,
    };

    if (!config.headers) {
      config.headers = headers;
    }

    if (
      !config.headers?.Authorization &&
      !config.url.includes("/session") &&
      !config.url.includes("/auca/account") &&
      !config.url.includes("/account") &&
      !config.url.includes("/login")
    ) {
      var header2: any;
      if (config.url.includes("/calculation/pro/file"))
      {
        header2 = {
          Accept: "*/*",
          "Accept-Language": i18next.language,
        };
      } else {
        header2 = {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Accept-Language": i18next.language,
      };
    }

      header2.Authorization = `Bearer ${
        store.getState().authManagement.user?.access_token
      }`;
      config.headers = header2;
      return config;
    }

    return config;
  },
  (err) => Promise.reject(err)
);

axiosInstanceAWS.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    const headers: any = {
      "Content-Type": "application/json",
      Accept: "*/*",

      "Accept-Language": i18next.language,
      "Access-Control-Allow-Origin" : "origin",
    };

    config.headers = headers;

    
    return config;
  },

  (err) => Promise.reject(err)
);



type Modules =
  | "account"
  | "session"
  | "api"
  | "survey"
  | "energySource"
  | "calculateEnergySource"
  | "calculate"
  | "subscribePlan"
  | "passwordChange"
  | "user"
  | "question"
  | "appUsage"
  | "energies"
  | "mockedDataForProPlan"
  | "calculationFile"
  | "profile";

const baseURL = process.env.REACT_APP_BACKEND_URL;

const mocked = {
  account: `${baseURL}/auca/account`,
  survey: `${baseURL}/auca/survey`,
  session: `${baseURL}/session`,
  api: `${baseURL}/api`,
  energySource: `${baseURL}/api/auca/energy-source`,
  calculateEnergySource: `${baseURL}/auca/calculation/simple/energy_sources`,
  calculate: `${baseURL}/auca/calculation/simple/process`,
  subscribePlan: `${baseURL}/auca/plan/`,
  passwordChange: `${baseURL}/core/profile/password-change`,
  user: `${baseURL}/api/auca/user`,
  question: `${baseURL}/api/auca/question`,
  appUsage: `${baseURL}/api/auca/app-usage`,
  energies: `${baseURL}/api/auca/energy-source`,
  mockedDataForProPlan: `${baseURL}/auca/calculation/demo`,
  calculationFile: `${baseURL}/auca/calculation/pro/file`,
  profile: `${baseURL}/auca/profile`,
};

export const getBaseUrlForAPI = (module: Modules): string => mocked[module];

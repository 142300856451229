import { AppDispatch } from "../index";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import { store } from "..";
import { logOutSession, refresh } from "./authActions";
import { addAPIcall, completeAPIcall } from "../slices/api";
import { saveQuestions, saveTotalQuestionsPages } from "store/slices/question";
import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";

const questionURL = getBaseUrlForAPI("question");
interface ISurveyQuestion {
  number: number;
  textEs: string;
  textEn: string;
  type: string;
}
interface ISurveyChoice {
  number: number;
  textEs: string;
  textEn: string;
  score: number;
  question: ISurveyQuestion;
}

export interface IQuestionFilter {
  id?: string;
  status?: string;
  number?: string;
  type?: string;
  textEn?: string;
  textEs?: string;
  pageSize?: number;
  pageNumber?: number;
  sortType?: string;
  sortBy?: string;
}

export const fetchFilteredQuestions =
  (params: IQuestionFilter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `fetchFilteredQuestions-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${questionURL}/search`, { params })
        .then(({ data }) => {
          dispatch(saveQuestions(data.content));
          dispatch(saveTotalQuestionsPages(data.totalPages));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const deleteQuestion =
  (questionId: number, params: IQuestionFilter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `deleteQuestion-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .delete(`${questionURL}/${questionId}`)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.questionDeleted"));
          dispatch(fetchFilteredQuestions(params));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const createQuestion =
  (question: ISurveyQuestion, params: IQuestionFilter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `createQuestion-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${questionURL}`, question)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.questionCreated"));
          dispatch(fetchFilteredQuestions(params));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const editQuestion =
  (questionId: number, question: ISurveyQuestion, params: IQuestionFilter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `editQuestion-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .put(`${questionURL}/${questionId}`, question)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.questionEdited"));
          dispatch(fetchFilteredQuestions(params));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const createChoice =
  (questionId: number, question: ISurveyChoice, params: IQuestionFilter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `createChoice-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${questionURL}/${questionId}/choice`, question)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.choiceCreated"));
          dispatch(fetchFilteredQuestions(params));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const editChoice =
  (
    questionId: number,
    choiceId: number,
    choice: ISurveyChoice,
    params: IQuestionFilter
  ) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `editChoice-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .put(`${questionURL}/${questionId}/choice/${choiceId}`, choice)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.choiceEdited"));
          dispatch(fetchFilteredQuestions(params));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const deleteChoice =
  (questionId: number, choiceId: number, params: IQuestionFilter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `deleteChoice-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .delete(`${questionURL}/${questionId}/choice/${choiceId}`)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.choiceDeleted"));
          dispatch(fetchFilteredQuestions(params));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

import { combineReducers } from "@reduxjs/toolkit";
import snackbarReducer from "./slices/snackbar";
import apiReducer from "./slices/api";
import authReducer from "./slices/auth";
import surveyReducer from "./slices/survey";
import planReducer from "./slices/plan";
import accountReducer from "./slices/account";
import userReducer from "./slices/user";
import questionReducer from "./slices/question";
import profileReducer from "./slices/profile";

export const rootReducer = combineReducers({
  snackbarManagement: snackbarReducer,
  api: apiReducer,
  authManagement: authReducer,
  surveyManagement: surveyReducer,
  sourcesManagement: planReducer,
  accountManagement: accountReducer,
  userManagement: userReducer,
  questionManagement: questionReducer,
  profileManagement: profileReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export interface ThunkAPI {
  state: RootState;
}

import React, { useState } from "react";
import Carousel, { arrowsPlugin, Dots } from "@brainhubeu/react-carousel";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "@brainhubeu/react-carousel/lib/style.css";

import styles from "./index.module.scss";

interface PungoCarouselProps {
  slides: React.ReactNode[];
  id: string | number;
}

const PungoCarousel: React.FC<PungoCarouselProps> = (props) => {
  const { slides, id } = props;
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleCurrentSlideChange = (index: number) => {
    setCurrentSlide(index);
  };
  
  return (
    <div key={id}>
      <Carousel
        plugins={[
          "infinite",
          {
            resolve: arrowsPlugin,
            options: {
              arrowLeft: <NavigateBeforeIcon />,
              arrowLeftDisabled: (
                <NavigateBeforeIcon className={styles.disabledArrows} />
              ),
              arrowRight: <NavigateNextIcon />,
              arrowRightDisabled: (
                <NavigateNextIcon className={styles.disabledArrows} />
              ),
              addArrowClickHandler: true,
            },
          },
        ]}
        value={currentSlide}
        onChange={handleCurrentSlideChange}
      >
        {slides}
      </Carousel>
      <Dots
        value={currentSlide}
        onChange={setCurrentSlide}
        number={slides.length}
      />
    </div>
  );
};

export default PungoCarousel;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PungoDemoResultsCard from "../../../../pungo-ui/PungoDemoResultsCard/index";
import { selectResults } from "store/slices/plan";
import "../../../../styles/variables.scss";
import styles from "./index.module.scss";
import colors from "../../../../styles/export.module.scss";
import PungoSlider, { IEnergyInfo } from "../../../../pungo-ui/PungoSlider";
import PungoDemoAccordion from "../../../../pungo-ui/PungoDemoAccordion";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { calculateEmissions } from "store/actions/planActions";
import { useAppDispatch } from "app/hooks";
import {
  clearResults,
  IEnergySource,
  selectEnergySources,
} from "store/slices/plan";
import PungoDemoInput from "pungo-ui/PungoDemoInput";

enum InputTypes {
  NUMBER = "number",
  TEXT = "text",
}

const DataInputSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const {
    emissionsIntensity,
    emissionsIntensityUnit,
    energyIntensity,
    energyIntensityUnit,
  } = useSelector(selectResults);
  const dispatch = useAppDispatch();
  const language = i18n.language;
  const isSpanishSelected = language === "es";
  const [isMostUsedSelected, setIsMostUsedSelected] = useState(false);
  const [options, setOptions] = useState<IEnergySource[]>([]);
  const [dailyProductionValue, setDailyProductionValue] = useState<
    number | null
  >(100);
  const [dailyProductionUnits, setDailyProductionUnits] =
    useState<string>("kg");
  const energySources = useSelector(selectEnergySources);
  const [slidersValues, setSlidersValues] = useState<IEnergyInfo[]>([]);
  const [emissionsCallTimeoutId, setEmissionsCallTimeoutId] = useState(0);

  const handleOnChangeSlider = (selection: IEnergyInfo) => {
    const index = slidersValues.findIndex(
      (slider) => slider.id === selection.id
    );
    const newSlidersValues = [...slidersValues];
    newSlidersValues[index]["value"] = selection.value;
    setSlidersValues(newSlidersValues);
  };

  const requestData = {
    energySources: slidersValues.sort((a, b) => a.id - b.id),
    productionValue: dailyProductionValue === null ? 0 : dailyProductionValue,
    productionUnit: dailyProductionUnits,
  };

  useEffect(() => {
    if (slidersValues.length && dailyProductionUnits && dailyProductionValue) {
      if (emissionsCallTimeoutId) clearTimeout(emissionsCallTimeoutId); // cancel previous timer
      setEmissionsCallTimeoutId(setTimeout(handleCalculateEmissions, 1000)); // start new timer
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slidersValues, dailyProductionUnits, dailyProductionValue]);

  function handleCalculateEmissions(data: Number): void {
    dispatch(calculateEmissions(requestData));
  }

  const getSliders = (sliders: IEnergySource[]) =>
    sliders.map((slider: IEnergySource) => (
      <div className={styles.sliderContainer} key={slider.id}>
        <PungoSlider
          id={slider.id}
          minValue={slider.minValue}
          maxValue={slider.maxValue}
          defaultValue={slider.defaultValue}
          description={t("DemoPage.DataInputSection.consumption", {
            name: isSpanishSelected ? slider.nameEs : slider.nameEn,
            units: isSpanishSelected ? slider.unitsEs : slider.unitsEn,
          })}
          setSelectedValue={handleOnChangeSlider}
        />
      </div>
    ));

  const handleOnSourceSelect = (event: any) => {
    const selectSourceId = event.target.value.length - 1;

    const index = options.findIndex(
      (item) => item.id === event.target.value[selectSourceId]
    );
    const selectedOption = energySources.find(
      ({ id }) => id === event.target.value[selectSourceId]
    );

    if (!(index > -1) && selectedOption) {
      setOptions((array) => [...array, selectedOption]);
      setSlidersValues((array) => [
        ...array,
        {
          id: selectedOption.id,
          value: selectedOption.defaultValue,
        },
      ]);
    } else {
      options.splice(index, 1);
      setOptions(
        options.filter((opt) => {
          return opt.id !== event.target.value[selectSourceId];
        })
      );
      setSlidersValues(
        slidersValues.filter(
          (opt) => opt.id !== event.target.value[selectSourceId]
        )
      );

      if (options.length === 0) dispatch(clearResults());
    }
  };
  const handleOnMostCommon = () => {
    const mostCommonIds = energySources.filter(({ mostCommon }) => mostCommon);
    const mostCommonForSliders = energySources
      .filter(({ mostCommon }) => mostCommon)
      .map(({ id, defaultValue }) => ({
        id,
        value: defaultValue,
      }));

    setIsMostUsedSelected(!isMostUsedSelected);
    if (!isMostUsedSelected) {
      setOptions(mostCommonIds);
      setSlidersValues(mostCommonForSliders);
    } else {
      setOptions([]);
      setSlidersValues([]);
      dispatch(clearResults());
    }
  };

  const handleOnDeleteChip = (chipId: number) => {
    setOptions((prev) => prev.filter(({ id }) => id !== chipId));
    setSlidersValues((prev) => prev.filter(({ id }) => id !== chipId));
  };

  const getEnergySourcesContent = () => (
    <>
      <Select
        multiple
        displayEmpty
        value={options}
        onChange={handleOnSourceSelect}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <span>{t("DemoPage.DataInputSection.chooseOption")}</span>;
          } else {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  padding: "10px 30px 10px 0",
                }}
              >
                {selected.map(({ id, nameEs, nameEn }) => {
                  return (
                    <Chip
                      key={id}
                      label={isSpanishSelected ? nameEs : nameEn}
                      clickable
                      deleteIcon={
                        <Cancel
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      onDelete={() => handleOnDeleteChip(id)}
                    />
                  );
                })}
              </Box>
            );
          }
        }}
        className={styles.select}
      >
        {energySources?.map((item: IEnergySource) => (
          <MenuItem className={styles.menuItem} key={item.id} value={item.id}>
            {isSpanishSelected ? item.nameEs : item.nameEn}
          </MenuItem>
        ))}
      </Select>
      <div className={styles.checkboxContainer}>
        <FormControlLabel
          control={<Checkbox onChange={handleOnMostCommon} />}
          label={t("DemoPage.DataInputSection.useMostUsedEnergySources")}
        />
      </div>
      {getSliders(options)}
    </>
  );

  const getDailyProductionContent = () => (
    <>
      <div className={styles.dailyContainer}>
        <div>
          <div className={styles.label}>
            {t("DemoPage.DataInputSection.value")}
          </div>
          <div>
            <PungoDemoInput
              value={dailyProductionValue}
              type={InputTypes.NUMBER}
              onChangeNumber={setDailyProductionValue}
              height="30px"
              width="100px"
            />
          </div>
        </div>
        <div>
          <div className={styles.label}>
            {t("DemoPage.DataInputSection.units")}
          </div>
          <div className={styles.inputContainer}>
            <PungoDemoInput
              value={dailyProductionUnits}
              type={InputTypes.TEXT}
              onChangeText={setDailyProductionUnits}
              height="30px"
              width="100px"
            />
          </div>
        </div>
      </div>
    </>
  );
  const getIndicatorsContent = () => (
    <>
      <div className={styles.results}>
        <div className={styles.resultCard}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.emissionsIntensity")}
            result={`${emissionsIntensity.toFixed(
              2
            )} ${emissionsIntensityUnit}`}
            color={colors.red1}
          />
        </div>
        <div className={styles.resultCard}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.energyIntensity")}
            result={`${energyIntensity.toFixed(2)} ${energyIntensityUnit}`}
            color={colors.red1}
          />
        </div>
      </div>
    </>
  );
  return (
    <PungoSectionCard title={t("DemoPage.DataInputSection.dataInput")}>
      <div className={styles.container}>
        <div className={styles.accordionContainer}>

          {/* indicadores por unidad de produccion */}
          <div className={styles.base}>
            <div className={styles.baseDataOutput}>
              <PungoDemoAccordion
                title={t("DemoPage.DataInputSection.dailyProduction")}
                content={getDailyProductionContent()}
              />
              <br></br>
              <PungoDemoAccordion
                title={t("DemoPage.ResultsSection.indicatorsPerUnit")}
                content={getIndicatorsContent()}
              />
            </div>
            <div className={styles.baseDataInput}>
              <PungoDemoAccordion
                title={t("DemoPage.DataInputSection.energySources")}
                content={getEnergySourcesContent()}
              />

            </div>
          </div>
        </div>
      </div>
    </PungoSectionCard>
  );
};

export default DataInputSection;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

interface IQuestionChoice {
  id: number;
  status: string;
  number: number;
  textEs: string;
  textEn: string;
  score: number;
}

interface IQuestion {
  id: number;
  status: string;
  number: number;
  textEs: string;
  textEn: string;
  type: string;
  scope: string;
  choices: IQuestionChoice[];
}

const emptyChoice: IQuestionChoice = {
  id: 0,
  status: "",
  number: 0,
  textEs: "",
  textEn: "",
  score: 0,
};

const emptyQuestion: IQuestion = {
  id: 0,
  status: "",
  number: 0,
  textEs: "",
  textEn: "",
  type: "",
  scope: "",
  choices: [emptyChoice],
};

const initialState = {
  questions: [emptyQuestion],
  totalQuestionsPages: 0,
};

const questionSlice = createSlice({
  name: "questionData",
  initialState,
  reducers: {
    clearQuestions: (state) => {
      state.questions = [];
    },
    saveQuestions: (state, action: PayloadAction<IQuestion[]>) => {
      state.questions = action.payload;
    },
    saveTotalQuestionsPages: (state, action: PayloadAction<number>) => {
      state.totalQuestionsPages = action.payload;
    },
  },
});
export const { saveQuestions, clearQuestions, saveTotalQuestionsPages } =
  questionSlice.actions;

export default questionSlice.reducer;

export const selectQuestions = (state: RootState): IQuestion[] =>
  state.questionManagement.questions;

export const selectTotalQuestionsPages = (state: RootState): number =>
  state.questionManagement.totalQuestionsPages;

import { saveSurvey, setAnswerLoader } from "store/slices/survey";
import { AppDispatch } from "../index";
import { v4 as uuidv4 } from "uuid";
import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";
import { addAPIcall, completeAPIcall } from "store/slices/api";
import moment from "moment";
import { store } from "../../store";
import { logOutSession, refresh } from "./authActions";

const surveyURL = getBaseUrlForAPI("survey");

interface ISurveyAnswer {
  questionNumber: number;
  choicesNumbers: number[];
}

export const getSurvey =
  () =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `getSurvey-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${surveyURL}`)
        .then(({ data }) => {
          dispatch(saveSurvey(data));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const sendAnswer =
  (answer: ISurveyAnswer, handleOnSend?: () => void) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;
    dispatch(setAnswerLoader(true));

    const localRequest = () => {
      const id = `sendAnswer-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${surveyURL}/answer`, answer)
        .then(() => {
          dispatch(
            displaySnackbarAPISuccess("successMessage.surveyAnswerSended")
          );
          if (handleOnSend) {
            handleOnSend();
          }
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
          dispatch(setAnswerLoader(false));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { useAppDispatch } from "app/hooks";
import { selectSurveyStatus, selectUserRole } from "store/slices/auth";
import DataInputSection from "./sections/DataInputSection";
import DataInputProSection from "components/DemoPage/sections/DataInputProSection";
import ResultsSection from "components/DemoPage/sections/ResultsSection";
import ResultsProSection from "components/DemoPage/sections/ResultsProSection";
import PungoButton from "pungo-ui/PungoButton";
import {
  getCalculateEnergySources,
  getMockedDataForProPlan,
} from "store/actions/planActions";
import {
  selectFetchedProResultsStatus,
  selectSelectedPlan,
} from "store/slices/plan";

import styles from "./index.module.scss";

const DemoPageFeria: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishLanguageSelected = i18n.language === "es";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSurveyCompleted = useSelector(selectSurveyStatus);
  const userRoles = useSelector(selectUserRole);
  const isFreeUser = userRoles?.includes("free_plan");
  const selectedPlan = useSelector(selectSelectedPlan);
  const areProResultsFetched = useSelector(selectFetchedProResultsStatus);

  useEffect(() => {
    if (!isSurveyCompleted) {
      navigate("/survey");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurveyCompleted]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCalculateEnergySources());

    if (isFreeUser) {
      dispatch(getMockedDataForProPlan());
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.container}>
      {selectedPlan === "free" ? (
        <DataInputSection />
      ) : areProResultsFetched ? (
        <DataInputProSection />
      ) : undefined}

      {selectedPlan === "free" ? (
        <ResultsSection />
      ) : areProResultsFetched ? (
        <ResultsProSection />
      ) : undefined}

      <div className={styles.languageSection}>
        <PungoButton
          label={`${t("language.englishLabel")}`}
          type="text"
          onClick={() => changeLanguage("en")}
          color="secondary"
          disabled={!isSpanishLanguageSelected}
        />
        <PungoButton
          label={`${t("language.spanishLabel")}`}
          type="text"
          onClick={() => changeLanguage("es")}
          color="secondary"
          disabled={isSpanishLanguageSelected}
        />
      </div>
      <div className={styles.copyright}>Copyright © 2023 Pungo</div>
    </section>
  );
};

export default DemoPageFeria;

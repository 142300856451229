import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import { AppDispatch } from "../index";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import { store } from "../../store";
import { saveFilteringResultPages, saveSources } from "store/slices/plan";
import { addAPIcall, completeAPIcall } from "store/slices/api";
import { logOutSession, refresh } from "./authActions";
import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";

const energySourceURL = getBaseUrlForAPI("energySource");
interface IEnergy {
  defaultValue: number;
  emissionFactor: number;
  emissionScopeEn: string;
  emissionScopeEs: string;
  energyContent: number;
  energyType: string;
  maxValue: number;
  minValue: number;
  mostCommon: boolean;
  nameEn: string;
  nameEs: string;
  unitCost: number;
  unitsEn: string;
  unitsEs: string;
  demoOrder: number;
}

export interface IEnergyFilter {
  id?: string;
  status?: string;
  mostCommon?: string;
  energyType?: string;
  unitCost?: string;
  nameEs?: string;
  nameEn?: string;
  unitsEs?: string;
  unitsEn?: string;
  defaultValue?: string;
  minValue?: string;
  maxValue?: string;
  emissionFactor?: string;
  emissionScopeEs?: string;
  emissionScopeEn?: string;
  demoOrderFilter?: string;
  pageSize?: number;
  pageNumber?: number;
  sortType?: string;
  sortBy?: string;
}

export const getFilteredEnergySources =
  (params: IEnergyFilter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `getFilteredEnergySources-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${energySourceURL}/search`, { params })
        .then(({ data }) => {
          dispatch(saveSources(data.content));
          dispatch(saveFilteringResultPages(data.totalPages));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const deleteEnergy =
  (energyId: number, params: IEnergyFilter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `deleteEnergy-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .delete(`${energySourceURL}/${energyId}`)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.energyDeleted"));
          dispatch(getFilteredEnergySources(params));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const createEnergy =
  (energyData: IEnergy, params: IEnergyFilter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `createEnergy-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${energySourceURL}`, energyData)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.energyCreated"));
          dispatch(getFilteredEnergySources(params));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };
export const editEnergy =
  (energyId: number, energyData: IEnergy, params: IEnergyFilter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `editEnergy-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .put(`${energySourceURL}/${energyId}`, energyData)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.energyEdited"));
          dispatch(getFilteredEnergySources(params));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };
    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

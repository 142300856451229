import html2canvas from "html2canvas";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
import * as pdfMake from "pdfmake/build/pdfmake.js";
import * as pdfFonts from "pdfmake/build/vfs_fonts.js";
import { Download } from "@mui/icons-material";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

import { selectProPlanResults } from "store/slices/plan";
import PungoDemoResultsCard from "pungo-ui/PungoDemoResultsCard";
import PungoButton from "pungo-ui/PungoButton";
import PungoTable from "pungo-ui/PungoTable";
import cost from "../../../../../assets/AucaImages/Cost.svg";
import optimizedCost from "../../../../../assets/AucaImages/OptimizedCost.svg";
import carbonEmissions from "../../../../../assets/AucaImages/carbonEmissions.svg";
import reducedCarbonEmissions from "../../../../../assets/AucaImages/reducedCarbonEmissions.svg";

import colors from "styles/export.module.scss";
import styles from "./index.module.scss";
import { ILabelForDatasets } from "../ResultSections/ContributionSection";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
);

const ResultsPdf: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishLanguageSelected = i18n.language === "es";

  const proPlanResults = useSelector(selectProPlanResults);
  const graphUnit = proPlanResults.production[0].unit || "";
  const {
    emissionSummary,
    costSummary,
    emissionIntensity,
    energyIntensity,
    energyEmissionContribution,
    processEmissionContribution,
    equipmentEmissionContribution,
    energyCostContribution,
    processCostContribution,
    equipmentCostContribution,
    optimization,
    actions,
  } = proPlanResults;

  var summaryImage: any;
  var indicatorsImage: any;
  var emissionAnalysisImage: any;
  var costAnalysisImage: any;
  var optimizationImage: any;
  var actionImage: any;

  const emissionSummaryDataForEmissions = {
    labels: emissionSummary?.map(({ date }) => date),
    datasets: [
      {
        data: emissionSummary?.map(({ dailyEmission }) => dailyEmission),
        borderWidth: 1,
        borderColor: colors.blue3,
        tension: 0.4,
        pointStyle: "circle",
        pointBackgroundColor: colors.red1,
        pointBorderColor: colors.red1,
        pointRadius: 4,
      },
    ],
  };

  const emissionSummaryDataForCosts = {
    labels: costSummary?.map(({ date }) => date),
    datasets: [
      {
        data: costSummary?.map(({ dailyCost }) => dailyCost),
        borderWidth: 1,
        borderColor: colors.blue3,
        tension: 0.4,
        pointStyle: "circle",
        pointBackgroundColor: colors.red1,
        pointBorderColor: colors.red1,
        pointRadius: 4,
      },
    ],
  };

  const LineChartConfigForEmissions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: `${t("proPlan.axisLabels.date")}`,
        },
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "kg CO2-eq",
        },
        grid: {
          lineWidth: 0,
        },
      },
    },
  };

  const LineChartConfigForCosts = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: `${t("proPlan.axisLabels.date")}`,
        },
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "USD",
        },
        grid: {
          lineWidth: 0,
        },
      },
    },
  };

  const labelsForTable = [
    t("proPlan.tableLabel.date"),
    t("proPlan.tableLabel.idProcess"),
    t("proPlan.tableLabel.idEquipment"),
    t("proPlan.tableLabel.energySource"),
    t("proPlan.tableLabel.emissions"),
    t("proPlan.tableLabel.scope"),
    t("proPlan.tableLabel.energyCost"),
    t("proPlan.tableLabel.energyContent"),
  ];

  const valuesForTable = proPlanResults?.emission.map(
    ({
      date,
      energySourceEn,
      energySourceEs,
      equipmentId,
      processId,
      emissionScopeEn,
      emissionScopeEs,
      emission,
      energyContent,
      energyCost,
    }) =>
      isSpanishLanguageSelected
        ? [
          date,
          processId,
          equipmentId,
          energySourceEs,
          emission,
          emissionScopeEs,
          energyCost,
          energyContent,
        ]
        : [
          date,
          processId,
          equipmentId,
          energySourceEn,
          emission,
          emissionScopeEn,
          energyCost,
          energyContent,
        ]
  );

  const emissionIntensityData = {
    labels: emissionIntensity?.map(({ date }) => date),
    datasets: [
      {
        data: emissionIntensity?.map(
          ({ emissionIntensity }) => emissionIntensity
        ),
        borderWidth: 1,
        borderColor: colors.blue3,
        tension: 0.4,
        pointStyle: "circle",
        pointBackgroundColor: colors.red1,
        pointBorderColor: colors.red1,
        pointRadius: 4,
      },
    ],
  };

  const lineChartConfigForEmissionIntensity = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: `${t("proPlan.axisLabels.date")}`,
        },
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: `kg CO2-eq / ${graphUnit}`,
        },
        grid: {
          lineWidth: 0,
        },
      },
    },
  };
  const energyIntensityData = {
    labels: energyIntensity?.map(({ date }) => date),
    datasets: [
      {
        data: energyIntensity?.map(({ energyIntensity }) => energyIntensity),
        borderWidth: 1,
        borderColor: colors.blue3,
        tension: 0.4,
        pointStyle: "circle",
        pointBackgroundColor: colors.red1,
        pointBorderColor: colors.red1,
        pointRadius: 4,
      },
    ],
  };

  const lineChartConfigForEnergyIntensity = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: `${t("proPlan.axisLabels.date")}`,
        },
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: `MJ / ${graphUnit}`,
        },
        grid: {
          lineWidth: 0,
        },
      },
    },
  };

  const barChartConfigForEmissions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        stacked: true,
        title: {
          display: true,
          text: `${t("proPlan.axisLabels.date")}`,
        },
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        stacked: true,
        display: true,
        title: {
          display: true,
          text: "kg CO2-eq",
        },

        grid: {
          lineWidth: 0,
        },
      },
    },
  };

  const barChartConfigForCost = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        stacked: true,
        title: {
          display: true,
          text: `${t("proPlan.axisLabels.date")}`,
        },
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        stacked: true,
        display: true,
        title: {
          display: true,
          text: "USD",
        },

        grid: {
          lineWidth: 0,
        },
      },
    },
  };

  const colorsForChart = [
    colors.green2,
    colors.green4,
    colors.blue1,
    colors.blue2,
    colors.blue3,
  ];

  const getDatesForLabelsForEnergy = () => {
    const dates: string[] = [];
    for (let i = 0; i < energyEmissionContribution.length; i++) {
      const date = energyEmissionContribution[i].date;
      if (!dates.includes(date)) {
        dates.push(date);
      }
    }
    return dates;
  };
  const getDatesForLabelsForEnergyCost = () => {
    const dates: string[] = [];
    for (let i = 0; i < energyCostContribution.length; i++) {
      const date = energyCostContribution[i].date;
      if (!dates.includes(date)) {
        dates.push(date);
      }
    }
    return dates;
  };

  const getDatesForLabelsForProcess = () => {
    const dates: string[] = [];
    for (let i = 0; i < processEmissionContribution.length; i++) {
      const date = processEmissionContribution[i].date;
      if (!dates.includes(date)) {
        dates.push(date);
      }
    }
    return dates;
  };

  const getDatesForLabelsForProcessCost = () => {
    const dates: string[] = [];
    for (let i = 0; i < processCostContribution.length; i++) {
      const date = processCostContribution[i].date;
      if (!dates.includes(date)) {
        dates.push(date);
      }
    }
    return dates;
  };

  const getDatesForLabelsForEquipment = () => {
    const dates: string[] = [];
    for (let i = 0; i < equipmentEmissionContribution.length; i++) {
      const date = equipmentEmissionContribution[i].date;
      if (!dates.includes(date)) {
        dates.push(date);
      }
    }
    return dates;
  };

  const getDatesForLabelsForEquipmentCost = () => {
    const dates: string[] = [];
    for (let i = 0; i < equipmentCostContribution.length; i++) {
      const date = equipmentCostContribution[i].date;
      if (!dates.includes(date)) {
        dates.push(date);
      }
    }
    return dates;
  };

  const getLabelsForDatasetsForEnergy = () => {
    const datasetsLabels: ILabelForDatasets[] = [];
    for (let i = 0; i < energyEmissionContribution.length; i++) {
      const label = energyEmissionContribution[i].labelEs;
      if (!datasetsLabels.find(({ labelEs }) => label === labelEs)) {
        datasetsLabels.push({
          labelEs: label,
          labelEn: energyEmissionContribution[i].labelEn,
        });
      }
    }
    return datasetsLabels;
  };

  const getLabelsForDatasetsForEnergyCost = () => {
    const datasetsLabels: ILabelForDatasets[] = [];
    for (let i = 0; i < energyCostContribution.length; i++) {
      const label = energyCostContribution[i].labelEs;
      if (!datasetsLabels.find(({ labelEs }) => label === labelEs)) {
        datasetsLabels.push({
          labelEs: label,
          labelEn: energyCostContribution[i].labelEn,
        });
      }
    }
    return datasetsLabels;
  };

  const getLabelsForDatasetsForProcess = () => {
    const datasetsLabels: ILabelForDatasets[] = [];
    for (let i = 0; i < processEmissionContribution.length; i++) {
      const label = processEmissionContribution[i].labelEs;
      if (!datasetsLabels.find(({ labelEs }) => label === labelEs)) {
        datasetsLabels.push({
          labelEs: label,
          labelEn: processEmissionContribution[i].labelEn,
        });
      }
    }
    return datasetsLabels;
  };

  const getLabelsForDatasetsForProcessCost = () => {
    const datasetsLabels: ILabelForDatasets[] = [];
    for (let i = 0; i < processCostContribution.length; i++) {
      const label = processCostContribution[i].labelEs;
      if (!datasetsLabels.find(({ labelEs }) => label === labelEs)) {
        datasetsLabels.push({
          labelEs: label,
          labelEn: processCostContribution[i].labelEn,
        });
      }
    }
    return datasetsLabels;
  };

  const getLabelsForDatasetsForEquipment = () => {
    const datasetsLabels: ILabelForDatasets[] = [];
    for (let i = 0; i < equipmentEmissionContribution.length; i++) {
      const label = equipmentEmissionContribution[i].labelEs;
      if (!datasetsLabels.find(({ labelEs }) => label === labelEs)) {
        datasetsLabels.push({
          labelEs: label,
          labelEn: equipmentEmissionContribution[i].labelEn,
        });
      }
    }
    return datasetsLabels;
  };

  const getLabelsForDatasetsForEquipmentCost = () => {
    const datasetsLabels: ILabelForDatasets[] = [];
    for (let i = 0; i < equipmentCostContribution.length; i++) {
      const label = equipmentCostContribution[i].labelEs;
      if (!datasetsLabels.find(({ labelEs }) => label === labelEs)) {
        datasetsLabels.push({
          labelEs: label,
          labelEn: equipmentCostContribution[i].labelEn,
        });
      }
    }
    return datasetsLabels;
  };

  const stackedDataForEnergy = {
    labels: getDatesForLabelsForEnergy(),
    datasets: getLabelsForDatasetsForEnergy()
      .map((label, index) => ({
        key: index,
        label: isSpanishLanguageSelected ? label.labelEs : label.labelEn,
        data: energyEmissionContribution
          .filter(({ labelEs }) => labelEs === label.labelEs)
          .map(({ value }) => value),
        backgroundColor: colorsForChart[index],
        order: 2,
      }))
      .concat([
        {
          type: "line",
          label: "Total",
          data: getDatesForLabelsForEnergy().map((date) =>
            energyEmissionContribution
              .filter((contribution) => date === contribution.date)
              .reduce((acc, currentValue) => acc + currentValue.value, 0)
          ),
          borderWidth: 1,
          borderColor: colors.blue3,
          tension: 0.4,
          pointStyle: "circle",
          pointBackgroundColor: colors.red1,
          pointBorderColor: colors.red1,
          pointRadius: 4,
          order: 1,
        } as any,
      ]),
  };

  const stackedDataForEnergyCost = {
    labels: getDatesForLabelsForEnergyCost(),
    datasets: getLabelsForDatasetsForEnergyCost()
      .map((label, index) => ({
        key: index,
        label: isSpanishLanguageSelected ? label.labelEs : label.labelEn,
        data: energyCostContribution
          .filter(({ labelEs }) => labelEs === label.labelEs)
          .map(({ value }) => value),
        backgroundColor: colorsForChart[index],
        order: 2,
      }))
      .concat([
        {
          type: "line",
          label: "Total",
          data: getDatesForLabelsForEnergyCost().map((date) =>
            energyCostContribution
              .filter((contribution) => date === contribution.date)
              .reduce((acc, currentValue) => acc + currentValue.value, 0)
          ),
          borderWidth: 1,
          borderColor: colors.blue3,
          tension: 0.4,
          pointStyle: "circle",
          pointBackgroundColor: colors.red1,
          pointBorderColor: colors.red1,
          pointRadius: 4,
          order: 1,
        } as any,
      ]),
  };

  const stackedDataForProcess = {
    labels: getDatesForLabelsForProcess(),
    datasets: getLabelsForDatasetsForProcess()
      .map((label, index) => ({
        key: index,
        label: isSpanishLanguageSelected ? label.labelEs : label.labelEn,
        data: processEmissionContribution
          .filter(({ labelEs }) => labelEs === label.labelEs)
          .map(({ value }) => value),
        backgroundColor: colorsForChart[index],
        order: 2,
      }))
      .concat([
        {
          type: "line",
          label: "Total",
          data: getDatesForLabelsForProcess().map((date) =>
            processEmissionContribution
              .filter((contribution) => date === contribution.date)
              .reduce((acc, currentValue) => acc + currentValue.value, 0)
          ),
          borderWidth: 1,
          borderColor: colors.blue3,
          tension: 0.4,
          pointStyle: "circle",
          pointBackgroundColor: colors.red1,
          pointBorderColor: colors.red1,
          pointRadius: 4,
          order: 1,
        } as any,
      ]),
  };

  const stackedDataForProcessCost = {
    labels: getDatesForLabelsForProcessCost(),
    datasets: getLabelsForDatasetsForProcessCost()
      .map((label, index) => ({
        key: index,
        label: isSpanishLanguageSelected ? label.labelEs : label.labelEn,
        data: processCostContribution
          .filter(({ labelEs }) => labelEs === label.labelEs)
          .map(({ value }) => value),
        backgroundColor: colorsForChart[index],
        order: 2,
      }))
      .concat([
        {
          type: "line",
          label: "Total",
          data: getDatesForLabelsForProcessCost().map((date) =>
            processCostContribution
              .filter((contribution) => date === contribution.date)
              .reduce((acc, currentValue) => acc + currentValue.value, 0)
          ),
          borderWidth: 1,
          borderColor: colors.blue3,
          tension: 0.4,
          pointStyle: "circle",
          pointBackgroundColor: colors.red1,
          pointBorderColor: colors.red1,
          pointRadius: 4,
          order: 1,
        } as any,
      ]),
  };

  const stackedDataForEquipment = {
    labels: getDatesForLabelsForEquipment(),
    datasets: getLabelsForDatasetsForEquipment()
      .map((label, index) => ({
        key: index,
        label: isSpanishLanguageSelected ? label.labelEs : label.labelEn,
        data: equipmentEmissionContribution
          .filter(({ labelEs }) => labelEs === label.labelEs)
          .map(({ value }) => value),
        backgroundColor: colorsForChart[index],
        order: 2,
      }))
      .concat([
        {
          type: "line",
          label: "Total",
          data: getDatesForLabelsForEquipment().map((date) =>
            equipmentEmissionContribution
              .filter((contribution) => date === contribution.date)
              .reduce((acc, currentValue) => acc + currentValue.value, 0)
          ),
          borderWidth: 1,
          borderColor: colors.blue3,
          tension: 0.4,
          pointStyle: "circle",
          pointBackgroundColor: colors.red1,
          pointBorderColor: colors.red1,
          pointRadius: 4,
          order: 1,
        } as any,
      ]),
  };

  const stackedDataForEquipmentCost = {
    labels: getDatesForLabelsForEquipmentCost(),
    datasets: getLabelsForDatasetsForEquipmentCost()
      .map((label, index) => ({
        key: index,
        label: isSpanishLanguageSelected ? label.labelEs : label.labelEn,
        data: equipmentCostContribution
          .filter(({ labelEs }) => labelEs === label.labelEs)
          .map(({ value }) => value),
        backgroundColor: colorsForChart[index],
        order: 2,
      }))
      .concat([
        {
          type: "line",
          label: "Total",
          data: getDatesForLabelsForEquipmentCost().map((date) =>
            equipmentCostContribution
              .filter((contribution) => date === contribution.date)
              .reduce((acc, currentValue) => acc + currentValue.value, 0)
          ),
          borderWidth: 1,
          borderColor: colors.blue3,
          tension: 0.4,
          pointStyle: "circle",
          pointBackgroundColor: colors.red1,
          pointBorderColor: colors.red1,
          pointRadius: 4,
          order: 1,
        } as any,
      ]),
  };

  const doughnutDataForEnergy = {
    labels: getLabelsForDatasetsForEnergy().map(({ labelEn, labelEs }) =>
      isSpanishLanguageSelected ? labelEs : labelEn
    ),
    datasets: [
      {
        data: getLabelsForDatasetsForEnergy().map(({ labelEs }) =>
          energyEmissionContribution
            .filter((contribution) => labelEs === contribution.labelEs)
            .reduce((acc, currentValue) => acc + currentValue.value, 0)
        ),
        backgroundColor: colorsForChart,
        hoverOffset: 4,
      },
    ],
  };

  const doughnutDataForEnergyCost = {
    labels: getLabelsForDatasetsForEnergyCost().map(({ labelEn, labelEs }) =>
      isSpanishLanguageSelected ? labelEs : labelEn
    ),
    datasets: [
      {
        data: getLabelsForDatasetsForEnergyCost().map(({ labelEs }) =>
          energyCostContribution
            .filter((contribution) => labelEs === contribution.labelEs)
            .reduce((acc, currentValue) => acc + currentValue.value, 0)
        ),
        backgroundColor: colorsForChart,
        hoverOffset: 4,
      },
    ],
  };

  const doughnutDataForProcess = {
    labels: getLabelsForDatasetsForProcess().map(({ labelEn, labelEs }) =>
      isSpanishLanguageSelected ? labelEs : labelEn
    ),
    datasets: [
      {
        data: getLabelsForDatasetsForProcess().map(({ labelEs }) =>
          processEmissionContribution
            .filter((contribution) => labelEs === contribution.labelEs)
            .reduce((acc, currentValue) => acc + currentValue.value, 0)
        ),
        backgroundColor: colorsForChart,
        hoverOffset: 4,
      },
    ],
  };

  const doughnutDataForProcessCost = {
    labels: getLabelsForDatasetsForProcessCost().map(({ labelEn, labelEs }) =>
      isSpanishLanguageSelected ? labelEs : labelEn
    ),
    datasets: [
      {
        data: getLabelsForDatasetsForProcessCost().map(({ labelEs }) =>
          processCostContribution
            .filter((contribution) => labelEs === contribution.labelEs)
            .reduce((acc, currentValue) => acc + currentValue.value, 0)
        ),
        backgroundColor: colorsForChart,
        hoverOffset: 4,
      },
    ],
  };

  const doughnutDataForEquipment = {
    labels: getLabelsForDatasetsForEquipment().map(({ labelEn, labelEs }) =>
      isSpanishLanguageSelected ? labelEs : labelEn
    ),
    datasets: [
      {
        data: getLabelsForDatasetsForEquipment().map(({ labelEs }) =>
          equipmentEmissionContribution
            .filter((contribution) => labelEs === contribution.labelEs)
            .reduce((acc, currentValue) => acc + currentValue.value, 0)
        ),
        backgroundColor: colorsForChart,
        hoverOffset: 4,
      },
    ],
  };

  const doughnutDataForEquipmentCost = {
    labels: getLabelsForDatasetsForEquipmentCost().map(
      ({ labelEn, labelEs }) =>
        isSpanishLanguageSelected ? labelEs : labelEn
    ),
    datasets: [
      {
        data: getLabelsForDatasetsForEquipmentCost().map(({ labelEs }) =>
          equipmentCostContribution
            .filter((contribution) => labelEs === contribution.labelEs)
            .reduce((acc, currentValue) => acc + currentValue.value, 0)
        ),
        backgroundColor: colorsForChart,
        hoverOffset: 4,
      },
    ],
  };

  const doughnutConfig = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },

      datalabels: {
        formatter: (value: any, ctx: any) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.forEach((element: any) => {
            sum += element;
          });
          let percentage = ((value * 100) / sum).toFixed(2) + "%";
          return percentage;
        },
        color: "#fff",
      },
    },
  };

  function processIndicators() {
    const indicators = document.getElementById("indicators");
    if (indicators) {
      html2canvas(indicators).then(function (canvas) {
        indicatorsImage = {
          image: canvas.toDataURL(),
          height: 1000,
          width: 500,
          style: {
            alignment: "center",
          },
        };
        processCriticalPointsForEmission();
      });
    } else {
      processCriticalPointsForEmission();
    }
  }

  function processCriticalPointsForEmission() {
    const criticalPointsForEmission = document.getElementById("criticalPointsForEmission");
    if (criticalPointsForEmission) {
      html2canvas(criticalPointsForEmission).then(function (canvas) {
        emissionAnalysisImage = {
          image: canvas.toDataURL(),
          height: 1000,
          width: 500,
          style: {
            alignment: "center",
          },
        };
        processCriticalPointsForCost();
      });
    } else {
      processCriticalPointsForCost();
    }
  }

  function processCriticalPointsForCost() {
    const criticalPointsForCost = document.getElementById("criticalPointsForCost");
    if (criticalPointsForCost) {
      html2canvas(criticalPointsForCost).then(function (canvas) {
        costAnalysisImage = {
          image: canvas.toDataURL(),
          height: 1000,
          width: 500,
          style: {
            alignment: "center",
          },
        };
        processOptimazation();
      });
    } else {
      processOptimazation();
    }
  }

  function processOptimazation() {
    const optimization = document.getElementById("optimization");
    if (optimization) {
      html2canvas(optimization).then(function (canvas) {
        optimizationImage = {
          image: canvas.toDataURL(),
          height: 1000,
          width: 500,
          style: {
            alignment: "center",
          },
        };
        processActions();
      });
    } else {
      processActions();
    }
  }

  function processActions() {
    const actions = document.getElementById("actions");
    if (actions) {
      html2canvas(actions).then(function (canvas) {
        actionImage = {
          image: canvas.toDataURL(),
          height: 1000,
          width: 500,
          style: {
            alignment: "center",
          },
        };
        processPdfCreation();
      });
    } else {
      processPdfCreation();
    }
  }

  function processPdfCreation() {
    const documentDefinition = {
      content: [
        summaryImage,
        indicatorsImage,
        emissionAnalysisImage,
        costAnalysisImage,
        optimizationImage,
        actionImage,
      ],
      pageSize: "A4",
      pageMargins: 20,
      pageBreak: "after",
    };

    const generatedFile = pdfMake.createPdf(documentDefinition);
    generatedFile.download("Results");
  }

  const onPrintDocument = () => {
    summaryImage = {};
    indicatorsImage = {};
    emissionAnalysisImage = {};
    costAnalysisImage = {};
    optimizationImage = {};
    actionImage = {};

    const summary = document.getElementById("summary");
    if (summary) {
      html2canvas(summary).then(function (canvas) {
        summaryImage = {
          image: canvas.toDataURL(),
          height: 1000,
          width: 500,

          style: {
            alignment: "center",
          },
        };
        processIndicators();
      });
    } else {
      processIndicators();
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.hiddenContent}>
        <div id="summary" className={styles.results}>
          <div className={styles.sectionTitle}>
            {t("proPlan.labels.emissionsAndCosts")}
          </div>
          <div className={styles.tab}> {t("proPlan.tabs.emissions")}</div>
          <PungoDemoResultsCard
            description={t("proPlan.resultLabel.emissions")}
            result={`${emissionSummary
              .reduce(
                (acc, currentValue) => acc + currentValue.dailyEmission,
                0
              )
              .toFixed(1)} kg CO2-eq`}
            color={colors.red1}
          />
          <div className={styles.chart}>
            <Line
              data={emissionSummaryDataForEmissions}
              options={LineChartConfigForEmissions}
            />
          </div>

          <div className={styles.tab}> {t("proPlan.tabs.costs")}</div>
          <PungoDemoResultsCard
            description={t("proPlan.resultLabel.costs")}
            result={`${costSummary
              .reduce((acc, currentValue) => acc + currentValue.dailyCost, 0)
              .toFixed(1)} USD`}
            color={colors.red1}
          />
          <div className={styles.chart}>
            <Line
              data={emissionSummaryDataForCosts}
              options={LineChartConfigForCosts}
            />
          </div>
          <div className={styles.table}>
            <PungoTable
              values={valuesForTable}
              labels={labelsForTable}
              height={500}
              personalizedWidth={25}
            />
          </div>
        </div>
        <div id="indicators" className={styles.results}>
          <div className={styles.sectionTitle}>
            {t("proPlan.labels.indicators")}
          </div>
          <div className={styles.tab}>
            {t("proPlan.tabs.emissionsIntensity")}
          </div>
          <PungoDemoResultsCard
            description={t("proPlan.resultLabel.emissionsIntensity")}
            result={`${(
              emissionIntensity.reduce(
                (acc, currentValue) => acc + currentValue.emissionIntensity,
                0
              ) / emissionIntensity.length
            ).toFixed(1)} kg CO2-eq`}
            color={colors.red1}
          />
          <div className={styles.chart}>
            <Line
              data={emissionIntensityData}
              options={lineChartConfigForEmissionIntensity}
            />
          </div>
          <div className={styles.tab}> {t("proPlan.tabs.energyIntensity")}</div>
          <PungoDemoResultsCard
            description={t("proPlan.resultLabel.energyIntensity")}
            result={`${(
              energyIntensity.reduce(
                (acc, currentValue) => acc + currentValue.energyIntensity,
                0
              ) / energyIntensity.length
            ).toFixed(1)} MJ / ${graphUnit}`}
            color={colors.red1}
          />
          <div className={styles.chart}>
            <Line
              data={energyIntensityData}
              options={lineChartConfigForEnergyIntensity}
            />
          </div>
        </div>
        <div id="criticalPointsForEmission" className={styles.results}>
          <div className={styles.sectionTitle}>
            {t("proPlan.labels.contribution")}
          </div>
          <div className={styles.tab}>{t("proPlan.tabs.emissions")}</div>
          <div className={styles.subTab}>{t("proPlan.tabs.energySource")}</div>
          <div className={styles.barChart}>
            <Bar
              data={stackedDataForEnergy}
              options={barChartConfigForEmissions}
            />
          </div>
          <div className={styles.barChart}>
            <Doughnut data={doughnutDataForEnergy} options={doughnutConfig} />
          </div>
          <div className={styles.subTab}>{t("proPlan.tabs.process")}</div>
          <div className={styles.barChart}>
            <Bar
              data={stackedDataForProcess}
              options={barChartConfigForEmissions}
            />
          </div>
          <div className={styles.barChart}>
            <Doughnut data={doughnutDataForProcess} options={doughnutConfig} />
          </div>
          <div className={styles.subTab}>{t("proPlan.tabs.equipment")}</div>
          <div className={styles.barChart}>
            <Bar
              data={stackedDataForEquipment}
              options={barChartConfigForEmissions}
            />
          </div>
          <div className={styles.barChart}>
            <Doughnut
              data={doughnutDataForEquipment}
              options={doughnutConfig}
            />
          </div>
        </div>
        <div id="criticalPointsForCost" className={styles.results}>
          <div className={styles.sectionTitle}>
            {t("proPlan.labels.contribution")}
          </div>
          <div className={styles.tab}>{t("proPlan.tabs.costs")}</div>
          <div className={styles.subTab}>{t("proPlan.tabs.energySource")}</div>
          <div className={styles.barChart}>
            <Bar
              data={stackedDataForEnergyCost}
              options={barChartConfigForCost}
            />
          </div>
          <div className={styles.barChart}>
            <Doughnut
              data={doughnutDataForEnergyCost}
              options={doughnutConfig}
            />
          </div>
          <div className={styles.subTab}>{t("proPlan.tabs.process")}</div>
          <div className={styles.barChart}>
            <Bar
              data={stackedDataForProcessCost}
              options={barChartConfigForCost}
            />
          </div>
          <div className={styles.barChart}>
            <Doughnut
              data={doughnutDataForProcessCost}
              options={doughnutConfig}
            />
          </div>
          <div className={styles.subTab}>{t("proPlan.tabs.equipment")}</div>
          <div className={styles.barChart}>
            <Bar
              data={stackedDataForEquipmentCost}
              options={barChartConfigForCost}
            />
          </div>
          <div className={styles.barChart}>
            <Doughnut
              data={doughnutDataForEquipmentCost}
              options={doughnutConfig}
            />
          </div>
        </div>
        <div id="optimization" className={styles.results}>
          <div className={styles.sectionTitle}>
            {t("proPlan.labels.optimization")}
          </div>
          <div className={styles.tab}>{t("proPlan.tabs.emissions")}</div>
          <div className={styles.flexContent}>
            <PungoDemoResultsCard
              description={t("proPlan.resultLabel.emissionWithNoOptimization")}
              result={`${optimization.emissionWithOutOptimization.toFixed(
                2
              )} kg CO2-eq`}
              color={colors.red1}
              image={carbonEmissions}
            />
            <PungoDemoResultsCard
              description={t("proPlan.resultLabel.emissionWithOptimization")}
              result={`${optimization.emissionOptimized.toFixed(2)} kg CO2-eq`}
              color={colors.green1}
              image={reducedCarbonEmissions}
              reducedEmissions={`${optimization.emissionReduced.toFixed(
                2
              )} kg CO2-eq`}
            />
          </div>
          <div className={styles.tab}>{t("proPlan.tabs.costs")}</div>
          <div className={styles.flexContent}>
            <PungoDemoResultsCard
              description={t("DemoPage.OptimizationSection.currentCosts")}
              result={`${optimization.costCurrent.toFixed(2)} USD`}
              color={colors.red1}
              image={cost}
            />
            <PungoDemoResultsCard
              description={t("DemoPage.OptimizationSection.optimizedCosts")}
              result={`${optimization.costOptimized.toFixed(2)} USD`}
              color={colors.green1}
              image={optimizedCost}
              reducedCost={`${optimization.costReduced.toFixed(2)} USD`}
            />
          </div>
        </div>
        <div id="actions" className={styles.results}>
          <div className={styles.sectionTitle}>
            {t("proPlan.labels.actions")}
          </div>
          {actions.map(({ name, notes, actions }) => (
            <div key={name}>
              <div className={styles.tab}>{name}</div>
              <div className={styles.resultMessage}>
                <div className={styles.message}>
                  <Trans>{notes}</Trans>
                </div>
              </div>
              <ol className={styles.actions}>
                {actions.map((action) => (
                  <li key={action}>
                    <Trans>{action}</Trans>
                  </li>
                ))}
              </ol>
            </div>
          ))}
        </div>
      </div>
      <PungoButton
        label={`${t("proPlan.downloadResults")}`}
        type="text"
        onClick={onPrintDocument}
        color="secondary"
        icon={<Download />}
      />
    </div>
  );
};

export default ResultsPdf;

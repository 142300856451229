import React from "react";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import classNames from "classnames";

const PungoSelectLanguage: React.FC = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  return (
    <section className={styles.container}>
      <div className={styles.label}>
        <button onClick={() => changeLanguage("es")}   className={classNames(styles.buttonES, {[styles.inactive]: language === "es",})}></button>
        <button onClick={() => changeLanguage("en")}   className={classNames(styles.buttonEN, {[styles.inactive]: language === "en",})}></button>
      </div>
    </section>
  );
};

export default PungoSelectLanguage;
